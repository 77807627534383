const STATE = {
  hasWebcam: undefined,
  hasMicrophone: undefined,
  hasSpeaker: undefined,
};

export async function listDevices(kind = null) {
  const md = navigator.mediaDevices;

  if (md && md.enumerateDevices) {
    const devices = await md.enumerateDevices();
    if (kind === null) {
      return devices;
    }

    kind = `${kind.toLowerCase()}input`;
    return devices.filter(item => item.kind === kind);
  }
  return [];
}

export function getUserMedia(constraints = null) {
  const md = navigator.mediaDevices;

  if (md && md.getUserMedia) {
    if (constraints === null) {
      constraints = {
        audio: false,
        video: true,
      };
    }
    return navigator.mediaDevices.getUserMedia(constraints);
  }

  throw new Error('Recurso não suportado pelo navegador');
}

export async function hasWebcam() {
  if (STATE.hasWebcam === undefined) {
    const devices = await listDevices();
    STATE.hasWebcam = devices.some(({ kind }) => kind === 'videoinput');
  }

  return STATE.hasWebcam;
}

export async function hasMicrophone() {
  if (STATE.hasMicrophone === undefined) {
    const devices = await listDevices();
    STATE.hasWebcam = devices.some(({ kind }) => kind === 'audioinput');
  }

  return STATE.hasMicrophone;
}

export async function hasSpeaker() {
  if (STATE.hasSpeaker === undefined) {
    const devices = await listDevices();
    STATE.hasSpeaker = devices.some(({ kind }) => kind === 'audiooutput');
  }

  return STATE.hasSpeaker;
}

export function getSupportedConstraints() {
  const md = navigator.mediaDevices;
  return md && md.getSupportedConstraints ? md.getSupportedConstraints() : {};
}
