export const options = [
  {
    type: 'hospitalization',
    name: 'Internamento',
    icon: ['fal', 'file-invoice-dollar'],
  },
  '-',
  {
    type: 'medical_record',
    name: 'Prontuário',
    icon: ['fal', 'stethoscope'],
  },
  {
    type: 'therapeutic_plan',
    name: 'Plano terapêutico',
    icon: ['fal', 'capsules'],
  },
];

export default options;
