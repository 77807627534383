<template>
  <div class="hospitalization-modal">
    <dx-modal :title="getTitle" size="lg"
              :value="show" @input="close" id="modal-hospitalization">
      <div class="loading loading-lg" v-if="loading" />
      <template v-else>
        <div v-if="isFinished">
          <div class="account-detail">
            <h6 class="text-primary">Dados do internamento</h6>
            <div class="columns">
              <div class="column col-3 form-group">
                <small>Data da internação</small>
                <div class="text-bold">
                  {{ form.startDate | date('datetime') }}
                </div>
              </div>
              <div class="column col-3 form-group">
                <small>Data da alta</small>
                <div class="text-bold">
                  {{ form.endDate | date('datetime') }}
                </div>
              </div>
              <div class="column col-4 form-group">
                <small>Motivo da alta</small>
                <div class="text-bold">
                  {{ form.hospitalDischarge.reason }}
                </div>
              </div>
            </div>
          </div>
          <div class="account-detail">
            <h6 class="text-primary">Dados do paciente</h6>
            <div class="columns">
              <div class="column col-4 form-group">
                <small>Nome do paciente</small>
                <div class="text-bold">{{ form.patient.name }}</div>
              </div>
              <div class="column col-4 form-group">
                <small>Data de nascimento</small>
                <div class="text-bold">
                  {{ form.patient.birthDate | date }}
                  <small>({{ form.patient.birthDate | dateOld }})</small>
                </div>
              </div>
              <div class="column col-4 form-group">
                <small>Sexo</small>
                <div class="text-bold">
                  {{ form.patient.gender === 'male' ? 'Masculino' : 'Feminino' }}
                </div>
              </div>
            </div>
          </div>
          <div class="account-detail">
            <h6 class="text-primary">Dados do atendimento</h6>
            <div class="columns">
              <div class="column col-4 form-group">
                <small>Médico responsável</small>
                <div class="text-bold">{{ form.professional.name }}</div>
              </div>
              <div class="column col-4 form-group">
                <small>Convênio</small>
                <div class="text-bold">
                  {{ form.insurance.name }}
                </div>
              </div>
              <div class="column col-4 form-group">
                <small>Procedimento principal</small>
                <div class="text-bold">
                  {{ form.expense.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="account-detail">
            <h6 class="text-primary">Despesas</h6>
            <div class="empty" v-if="form.items.length === 0">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'file-invoice-dollar']" size="3x"></fa-icon>
              </div>
              <p class="empty-subtitle">
                Nenhuma despesa registrada nesta conta
              </p>
            </div>
            <template v-else>
              <table class="table table-hover">
                <thead>
                <tr>
                  <th class="hide-md hide-sm">#</th>
                  <th class="hide-md hide-sm">Data e hora</th>
                  <th class="hide-md hide-sm">Tipo</th>
                  <th class="hide-md hide-sm">Status</th>
                  <th class="hide-md hide-sm">Convênio</th>
                  <th>Despesa</th>
                  <th class="text-center">Quantidade</th>
                  <th class="hide-md hide-sm text-right">Valor</th>
                  <th class="text-right">Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in form.items" :key="i">
                  <td class="hide-md hide-sm">{{ i + 1 }}</td>
                  <td class="hide-md hide-sm">{{ item.date | date('datetime') }}</td>
                  <td class="hide-md hide-sm">{{ type.getName(item.type) }}</td>
                  <td class="hide-md hide-sm">{{ status.getName(item.status) }}</td>
                  <td class="hide-md hide-sm">{{ item.insurance.name }}</td>
                  <td>{{ item.expense.name }}</td>
                  <td class="text-center">{{ item.quantity }}</td>
                  <td class="hide-md hide-sm text-right">{{ item.particularValue | currency }}</td>
                  <td class="text-right">
                    {{ item.particularValue * item.quantity | currency }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="text-right mt-2">
                <span class="h6 mr-2">{{ total | currency }}</span>
              </div>
            </template>
          </div>
        </div>
        <st-tabs ref="hospitalization-tabs" v-else>
          <st-tab id="patient" name="Dados do paciente">
            <patient-data
              ref="patientData"
              :id="form.patient.id"
              @getInsurance="getPatientInsurance"
            />
          </st-tab>
          <st-tab id="hospitalization" name="Dados do internamento">
            <div class="columns form-group">
              <div class="column col-3 col-md-12 col-sm-12">
                <label class="form-label">Data e hora</label>
                <div class="form-input bg-gray text-bold">
                  {{ form.startDate | date('datetime') }}
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column col-5 col-sm-12 form-group"
                   :class="{'has-error': $v.form.insurance.plan.id.$error}">
                <label class="form-label">Convênio</label>
                <select id="insurance" class="form-select"
                        v-model="form.insurance.plan.id"
                        :disabled="!!form.expense.id"
                        @blur="$v.form.insurance.plan.id.$touch()">
                  <option value="">[Selecione o convênio]</option>
                  <option v-for="(item, i) in insurances"
                          :value="item.plan.id" :key="i">{{ item.customName }}</option>
                </select>
                <template v-if="$v.form.insurance.plan.id.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.insurance.plan.id.required">
                    Campo obrigatório
                  </div>
                </template>
              </div>
              <div class="column col-4 col-md-4 col-sm-12 form-group">
                <label for="insurance-record" class="form-label">Carteira</label>
                <input type="text" id="insurance-record" name="insurance-record"
                       v-model="form.insurance.record" class="form-input"
                       placeholder="000000000" autocomplete="nope" v-mask-number
                       :disabled="!form.insurance.plan.id">
              </div>
              <div class="column col-3 col-sm-12 form-group"
                   :class="{'has-error': $v.form.insurance.validity.$error}">
                <label class="form-label">Validade</label>
                <dx-input-date type="text" id="insurance-validity"
                               v-model="form.insurance.validity" class="form-input"
                               @blur="$v.form.insurance.validity.$touch()"
                               :disabled="!form.insurance.plan.id" />
                <template v-if="$v.form.insurance.validity.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.insurance.validity.date">
                    Data inválida
                  </div>
                  <div class="form-input-hint"
                       v-else-if="!$v.form.insurance.validity.minDate">
                    Carteira vencida
                  </div>
                </template>
              </div>
              <div class="column col-5 col-sm-12 form-group"
                   :class="{'has-error': $v.form.professional.id.$error}">
                <label class="form-label">
                  Médico responsável
                </label>
                <select id="hospitalization-professional" class="form-select"
                        v-model="form.professional.id"
                        :disabled="!!form.expense.id"
                        @blur="$v.form.professional.id.$touch()">
                  <option value="">[Selecione]</option>
                  <option v-for="(item, i) in professionals"
                          :value="item.id" :key="i">{{ item.name }}</option>
                </select>
                <template v-if="$v.form.professional.id.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.professional.id.required">Campo obrigatório</div>
                </template>
              </div>
              <div class="column col-7 col-sm-12 form-group"
                   :class="{'has-error': $v.form.expense.id.$error}">
                <label class="form-label">Procedimento</label>
                <dx-autocomplete
                  v-model="expense"
                  :source="findExpense"
                  label="name"
                  track-by="id"
                  :readonly="!canSearch"
                  @input="setExpense"
                  @blur="$v.form.expense.id.$touch()"
                  :debounce="800"
                  placeholder="Informe o código ou nome da consulta ou outros..."
                  input-id="expense-name">
                  <button v-if="form.expense.id"
                          slot="action"
                          @click="removeExpense"
                          class="btn btn-action input-group-btn btn-icon btn-gray">
                    <fa-icon :icon="['fal', 'times']"></fa-icon>
                  </button>
                  <button v-else
                          slot="action"
                          @click="openExpenseModal"
                          class="btn btn-action input-group-btn btn-icon btn-neutral"
                          :class="{loading: expenseModal.loading}"
                          tabindex="-1"
                          :disabled="!canSearch || expenseModal.loading">
                    <fa-icon :icon="['fal', 'search']"></fa-icon>
                  </button>
                  <template v-slot="{ item }">
                    <a>
                      <fa-icon :icon="['fal', 'file-medical-alt']" class="text-primary"/>
                      <span class="ml-1">
                        {{ item.code | tuss }} - {{ item.expense.name }}
                        <span v-if="item.expense.specialty"> ({{
                            item.expense.specialty.name }})
                        </span>
                      </span>
                    </a>
                  </template>
                </dx-autocomplete>
                <template v-if="$v.form.expense.id.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.expense.id.required">Campo obrigatório</div>
                </template>
              </div>
              <div class="column col-4 col-sm-12 form-group"
                   :class="{'has-error': $v.form.place.id.$error}">
                <label class="form-label">Local</label>
                <select id="hospitalization-place" class="form-select"
                        v-model="form.place.id"
                        @blur="$v.form.place.id.$touch()"
                        @change="loadBeds">
                  <option value="">[Selecione]</option>
                  <option v-for="(item, i) in places"
                          :value="item.id" :key="i">{{ item.name }}</option>
                </select>
                <template v-if="$v.form.place.id.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.place.id.required">Campo obrigatório</div>
                </template>
              </div>
              <div class="column col-3 col-sm-12 form-group"
                   :class="{'has-error': $v.form.place.bed.id.$error}">
                <label class="form-label">Leito</label>
                <select id="hospitalization-bed" class="form-select"
                        v-model="form.place.bed.id"
                        @blur="$v.form.place.bed.id.$touch()"
                        @change="setAccommodation">
                  <option value="">[Selecione]</option>
                  <option v-for="(item, i) in beds"
                          :value="item.id" :key="i">{{ item.name }}</option>
                </select>
                <template v-if="$v.form.place.bed.id.$error">
                  <div class="form-input-hint"
                       v-if="!$v.form.place.bed.id.required">Campo obrigatório</div>
                </template>
              </div>
              <div class="column col-5 col-sm-12 form-group">
                <label for="hospitalization-accommodation" class="form-label">
                  Acomodação
                </label>
                <input id="hospitalization-accommodation" class="form-input"
                       v-model="form.place.bed.accommodation.name" readonly>
              </div>
            </div>
          </st-tab>
          <st-tab id="account" name="Conta do paciente">
            <expense-data
              v-if="form.id"
              :id="form.id"
              :insurance-plan-id="form.insurance.plan.id"
              @finished="load"
            />
            <div class="empty mt-2" v-else>
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'file-invoice-dollar']" size="3x"></fa-icon>
              </div>
              <p class="empty-title h5">Conta do paciente</p>
              <p class="empty-subtitle">
                Nenhuma conta criada.
                Salve as informações de internação para que a conta seja gerada.
              </p>
              <button class="btn btn-primary btn-icon btn-icon-left mt-2"
                      :class="{loading: saving}"
                      :disabled="saving"
                      @click="save('hospitalized')">
                <fa-icon :icon="['fal', 'save']"/>Salvar internamento
              </button>
            </div>
          </st-tab>
        </st-tabs>
        <template slot="footer">
          <button class="btn btn-gray btn-icon btn-icon-left mr-1"
                  @click="openPrintModal">
            <fa-icon :icon="['fal', 'print']"/>
            Imprimir
          </button>
          <button class="btn btn-primary btn-icon btn-icon-left mr-1"
                  :class="{loading: saving}"
                  :disabled="saving" v-if="!isFinished"
                  @click="save">
            <fa-icon :icon="['fal', 'save']"/>
            Salvar
          </button>
          <button class="btn" @click="close">Sair</button>
        </template>
      </template>
    </dx-modal>
    <dx-modal title="Lista de procedimentos"
              v-model="expenseModal.show" size="lg"
              v-if="expenseModal.show"
              id="modal-expenses-appointment">
      <div class="empty mt-2" v-if="expenseModal.items.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
        </div>
        <p class="empty-title h6">Procedimentos</p>
        <p class="empty-subtitle">
          Nenhum procedimento encontrado para esta agenda. Verifique as configurações.
        </p>
      </div>
      <table class="table table-hover expense-modal-table" v-else>
        <thead>
        <tr>
          <th>Código</th>
          <th>Nome</th>
          <th>Especialidade</th>
          <th class="text-right">Valor paciente</th>
        </tr>
        </thead>
        <tbody>
        <tr class="c-hand" v-for="(item, i) in expenseModal.items" :key="i"
            @click="selectExpense(item)">
          <td>
            <span v-if="item.code">{{ item.code | tuss }}</span>
            <span v-else>-</span>
          </td>
          <td>{{ item.expense.name }}</td>
          <td>
            <span v-if="item.expense.specialty">
              {{ item.expense.specialty.name }}
            </span>
            <span v-else>-</span>
          </td>
          <td class="text-right">
            <span v-if="item.definition">
              {{ item.definition.particularValue | currency }}
            </span>
          </td>
        </tr>
        </tbody>
      </table>
      <template v-slot:footer>
        <button class="btn" @click="expenseModal.show = false">Sair</button>
      </template>
    </dx-modal>
    <dx-modal title="Imprimir"
              v-model="printModal.show"
              v-if="printModal.show"
              id="modal-print-hospitalization"
              size="sm"
    >
      <div class="columns">
        <div class="column col-12 form-group">
          <button class="btn btn-gray btn-block"
                  @click="printSurgeryStatementResponsibility">
            Termo de responsabilidade
          </button>
        </div>
        <div class="column col-12 form-group">
          <button class="btn btn-gray btn-block"
                  @click="printSurgeryConsentForm">
            Termo de consentimento
          </button>
        </div>
        <div class="column col-12 form-group">
          <button class="btn btn-gray btn-block"
                  @click="printSurgeryDescription">
            Ficha de descritiva de cirurgia
          </button>
        </div>
        <div class="column col-12 form-group">
          <button class="btn btn-gray btn-block"
                  @click="printHospitalization">
            Evolução de internação
          </button>
        </div>
        <div class="column col-12 form-group">
          <button class="btn btn-gray btn-block"
                  @click="printSurgeryProducts">
            Saída de sala
          </button>
        </div>
        <div class="column col-12 form-group">
          <button class="btn btn-gray btn-block"
                  @click="printModal.show = false">
            Etiqueta do paciente
          </button>
        </div>
        <div class="column col-12 form-group">
          <button class="btn btn-gray btn-block"
                  @click="printModal.show = false">
            Identificação para o leito
          </button>
        </div>
      </div>
      <template slot="footer">
        <button class="btn" @click="printModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import formMixin from 'src/mixins/form';
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { mergeFrom } from '@/helpers/object';
import * as status from 'src/data/patient-account-item-statuses';
import * as type from 'src/data/patient-account-item-types';
import * as professionalApi from '@/app/professional/api';
import { date, minDate } from '../../../../data/validators';
import ExpenseData from './Expense.vue';
import PatientData from './Patient.vue';

export default {
  props: {
    show: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    patientId: {
      type: String,
    },
  },
  components: {
    ExpenseData,
    PatientData,
  },
  mixins: [
    formMixin,
  ],
  data() {
    return {
      loading: false,
      saving: false,
      expense: null,
      insurances: [],
      professionals: [],
      places: [],
      beds: [],
      status,
      type,
      form: this.blankForm(),
      expenseModal: {
        loading: false,
        show: false,
        items: [],
      },
      printModal: {
        show: false,
      },
    };
  },
  async mounted() {
    this.loading = true;
    this.loadInsurances();
    this.loadProfessionals();
    this.loadPlaces();
    if (this.id) {
      await this.load();
      this.loadBeds();
    } else if (this.patientId) {
      this.form.patient.id = this.patientId;
    }
    this.loading = false;
  },
  computed: {
    canSearch() {
      return this.form.insurance
        && this.form.insurance.plan
        && this.form.insurance.plan.id
        && this.form.professional
        && this.form.professional.id
        && !this.form.expense.id;
    },
    isFinished() {
      return this.form.status === 'finished';
    },
    total() {
      return this.form.items.reduce((a, b) => a + (b.particularValue * b.quantity), 0);
    },
    getTitle() {
      if (this.loading) {
        return 'Aguarde, carregando...';
      }
      if (this.form.id) {
        return `Internamento - ${this.form.patient.name}`;
      }
      return 'Novo internamento';
    },
  },
  validations() {
    return {
      form: {
        place: {
          id: { required },
          bed: {
            id: { required },
          },
        },
        professional: {
          id: { required },
        },
        expense: {
          id: { required },
        },
        insurance: {
          validity: {
            date,
            minDate: minDate(moment().format('YYYY-MM-DD')),
          },
          plan: {
            id: { required },
          },
        },
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    load() {
      this.loading = true;

      return this.$http
        .get(`/hospitalizations/${this.id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
          this.expense = this.form.expense;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.insurances = data.items;
        })
        .catch(() => {});
    },
    loadPlaces() {
      const params = {
        active: true,
        limit: 0,
        type: 'hospitalization',
      };

      if (!this.id) {
        params.bedStatus = 'available';
      }

      return this.$http
        .get('/places', { params })
        .then(({ data }) => {
          this.places = data.items;
        })
        .catch(() => {});
    },
    loadBeds() {
      this.beds = [];
      if (!this.form.place.id) {
        this.form.place.bed = this.blankForm().place.bed;
        return null;
      }

      const params = {
        active: true,
        limit: 0,
      };

      if (!this.id) {
        params.status = 'available';
      }

      return this.$http
        .get(`/places/${this.form.place.id}/beds`, { params })
        .then(({ data }) => {
          this.beds = data.items;
        })
        .catch(() => {});
    },
    loadProfessionals() {
      return professionalApi.allActive(true)
        .then((data) => {
          this.professionals = data.items;
        })
        .catch(this.$toast.error);
    },
    findExpense(search) {
      const params = {
        search,
        professionalId: this.form.professional.id,
        // insuranceId: this.form.insurance.id,
        planId: this.form.insurance.plan.id,
        limit: 30,
        offset: 0,
      };

      return this.$http.get('/expense-insurances', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{
              id: null,
              expense: {
                id: null,
                name: 'Procedimento não encontrado neste convênio',
              },
              definition: {
                particularValue: 0,
              },
            }];
          }
          return data.items;
        })
        .catch(this.$toast.error);
    },
    setExpense(expense) {
      if (!expense || expense.id === null) {
        return;
      }
      this.expense = {
        id: expense.expense.id,
        name: expense.expense.name,
      };
      this.form.expense = {
        id: expense.expense.id,
        name: expense.expense.name,
      };
    },
    removeExpense() {
      this.$v.form.expense.$reset();
      this.expense = null;
      this.form.expense = this.blankForm().expense;
    },
    async openExpenseModal() {
      this.expenseModal.loading = true;
      this.expenseModal.items = [];
      await this.findExpense()
        .then((items) => {
          if (items) {
            this.expenseModal.items = items;
          }
        });
      this.expenseModal.show = true;
      this.expenseModal.loading = false;
    },
    selectExpense(item) {
      this.setExpense(item);
      this.expenseModal.show = false;
    },
    setAccommodation() {
      if (!this.form.place.bed.id) {
        this.form.place.bed.accommodation = this.blankForm().place.bed.accommodation;
        return;
      }
      const found = this.beds.find(({ id }) => id.includes(this.form.place.bed.id));
      if (found) {
        this.form.place.bed.accommodation = found.accommodationType;
      }
    },
    getPatientInsurance(insurance) {
      if (!this.form.insurance.plan.id) {
        this.form.insurance.plan.id = insurance.plan.id;
        this.form.insurance.record = insurance.record;
        this.form.insurance.validity = insurance.validity;
      }
    },
    async save() {
      if (!this.$refs.patientData.validate()) {
        this.$refs['hospitalization-tabs'].selectTab('patient');
        return;
      }

      this.$v.form.$touch();
      if (this.$v.form.$error) {
        this.$refs['hospitalization-tabs'].selectTab('hospitalization');
        return;
      }

      this.saving = true;

      const patient = await this.$refs.patientData.save();

      if (!patient || !patient.id) {
        this.saving = false;
        this.$toast.show('Ocorreu um erro ao salvar o paciente. Tente novamente!',
          { type: 'error' });
        return;
      }

      const data = this.clone(this.form);
      data.placeId = data.place.id;
      data.bedId = data.place.bed.id;
      data.professionalId = data.professional.id;
      data.expenseId = data.expense.id;
      data.patientId = patient.id;
      data.insurance = {
        planId: data.insurance.plan.id,
        record: data.insurance.record,
        validity: data.insurance.validity,
      };
      delete data.place;
      delete data.professional;
      delete data.patient;
      delete data.expense;

      const request = !data.id
        ? this.$http.post('/hospitalizations', data)
        : this.$http.put(`/hospitalizations/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          this.form.id = result.id;
          this.$toast.success('Registro salvo!');
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro ao salvar. Tente novamente!');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    blankForm() {
      return {
        id: '',
        status: 'open',
        startDate: moment().format('YYYY-MM-DD[T]HH:mm'),
        endDate: '',
        place: {
          id: '',
          name: '',
          date: '',
          bed: {
            id: '',
            name: '',
            accommodation: {
              id: '',
              name: '',
            },
          },
        },
        expense: {
          id: '',
          name: '',
        },
        professional: {
          id: '',
          name: '',
        },
        patient: {
          id: '',
          name: '',
          birthDate: '',
          gender: '',
          identity: {
            type: 'cpf',
            value: '',
          },
        },
        insurance: {
          id: '',
          name: '',
          type: '',
          plan: {
            id: '',
            name: '',
          },
          record: '',
          validity: '',
          imageUrl: '',
        },
        hospitalDischarge: {
          reason: '',
          notes: '',
        },
        items: [],
      };
    },
    openPrintModal() {
      this.printModal.show = true;
    },
    printSurgeryDescription() {
      const params = {};
      return this.$file
        .print('/hospitalizations/1/print-surgery-descriptions', params)
        .catch(() => {});
    },
    printSurgeryStatementResponsibility() {
      const params = {};
      return this.$file
        .print('/hospitalizations/1/print-surgery-statement-responsibility', params)
        .catch(() => {});
    },
    printSurgeryConsentForm() {
      const params = {};
      return this.$file
        .print('/hospitalizations/1/print-surgery-consent-form', params)
        .catch(() => {});
    },
    printHospitalization() {
      const params = {};
      return this.$file
        .print('/hospitalizations/1/print', params)
        .catch(() => {});
    },
    printSurgeryProducts() {
      const params = {};
      return this.$file
        .print('/hospitalizations/1/print-surgery-products', params)
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
#modal-hospitalization {
  .account-detail {
    background-color: $gray-color-ultra-light;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin: $layout-spacing 0;
    padding: $layout-spacing;
  }
}
</style>
