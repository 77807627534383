<template>
  <dx-modal title="Informações" :value="show" @input="close"
            id="modal-appointment-info" size="sm">
    <div class="tile-item">
      <div class="tile-icon text-info">
        <fa-icon :icon="['fal', 'calendar-alt']"></fa-icon>
      </div>
      <div class="tile-label">Agendados</div>
      <div class="tile-value">{{ stats.scheduled }}</div>
    </div>
    <div class="tile-item">
      <div class="tile-icon text-error">
        <fa-icon :icon="['fal', 'calendar-times']"></fa-icon>
      </div>
      <div class="tile-label">Cancelados</div>
      <div class="tile-value">{{ stats.canceled }}</div>
    </div>
    <div class="tile-item">
      <div class="tile-icon text-success">
        <fa-icon :icon="['fal', 'calendar-check']"></fa-icon>
      </div>
      <div class="tile-label">Disponíveis para atendimento</div>
      <div class="tile-value">{{ totalAttended }}</div>
    </div>
    <div class="tile-item">
      <div class="tile-icon text-gray">
        <fa-icon :icon="['fal', 'file-medical-alt']"></fa-icon>
      </div>
      <div class="tile-label">Total de procedimentos</div>
      <div class="tile-value">{{ stats.expenseQty }}</div>
    </div>
    <div class="block">
      <h5 class="h6 block-title">Convênios</h5>
      <div class="card">
        <div class="card-body">
          <counter-bar
            v-for="(insurance, i) in stats.insurances"
            :key="i"
            :class="`counter-${i + 1}`"
            :label="insurance.name"
            :value="insurance.count"
            :total="totalInsurances"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="divider" data-content="" />
      <h5 class="h6 block-title">Procedimentos</h5>
      <div class="card">
        <div class="card-body">
          <counter-bar
            v-for="(expense, i) in stats.expenses"
            :key="i"
            :class="`counter-${i + 1}`"
            :label="expense.name"
            :value="expense.count"
            :total="totalExpenses"
          />
        </div>
      </div>
    </div>
    <template slot="footer">
      <button class="btn"
              @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import counterBar from '../../../components/counter-bar/CounterBar.vue';

export default {
  components: {
    counterBar,
  },
  props: {
    show: {
      type: Boolean,
    },
    date: {
      type: Date,
    },
    scheduleId: {
      type: String,
    },
  },
  data() {
    return {
      stats: {
        scheduled: 0,
        confirmed: 0,
        canceled: 0,
        returned: 0,
        noAccount: 0,
        account: 0,
        expenseQty: 0,
        patients: [],
        ageGroups: [],
        insurances: [],
        expenses: [],
        services: [],
      },
    };
  },
  mounted() {
    this.loadStats();
  },
  computed: {
    ...mapGetters({
      isProfessionalOnly: 'isProfessionalOnly',
    }),
    totalAttended() {
      return this.stats.scheduled - this.stats.canceled;
    },
    totalInsurances() {
      return this.stats.insurances.reduce((total, { count }) => total + count, 0);
    },
    totalExpenses() {
      return this.stats.expenses.reduce((total, { count }) => total + count, 0);
    },
  },
  methods: {
    async loadStats() {
      const params = {
        startDate: moment(this.date).startOf('day')
          .format('YYYY-MM-DD[T]HH:mm'),
        endDate: moment(this.date).endOf('day')
          .format('YYYY-MM-DD[T]HH:mm'),
      };

      if (this.scheduleId) {
        params.scheduleId = this.scheduleId;
      }

      if (this.isProfessionalOnly) {
        params.professionalId = this.user.id;
      }

      await this.$http.get('/stats', { params })
        .then(({ data }) => {
          this.stats = data;
        })
        .catch(() => {});
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/variables';
  @import 'src/assets/scss/colors';
  #modal-appointment-info {
    .modal.modal-sm .modal-container {
      max-width: 20rem;
    }
    .card .card-body {
      padding: 0;
    }
    .tile-item {
      display: flex;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      padding: $layout-spacing;
      margin-bottom: $layout-spacing;
    }
    .tile-icon {
      margin-right: $layout-spacing;
      svg {
        display: block;
        height: 1rem;
        margin: 0 auto;
        width: auto;
      }
    }
    .tile-label {
      flex: 1;
      font-size: $font-size-sm;
      line-height: $font-size;
      margin: $layout-spacing-sm 0 calc($layout-spacing-sm / 2) 0;
    }
    .tile-value {
      color: $secondary-color;
      font-size: $font-size-lg;
      font-weight: 600;
    }
    .block {
      margin-top: $layout-spacing-lg;
      &:first-child {
        margin-top: 0;
      }
      .block-title {
        margin: 0 0 $layout-spacing 0;
      }
    }
    .indicators {
      display: flex;
      &:last-child {
        margin-top: $layout-spacing-lg;
      }
      .counter-tile {
        flex: 1;
        margin-left: $layout-spacing-lg;
        &:first-child {
          margin-left: 0;
        }
        &.scheduled {
          .tile {
            color: $primary-color;
          }
        }
        &.confirmed {
          .tile {
            color: $green-color-600;
          }
        }
        &.canceled {
          .tile {
            color: $error-color;
          }
        }
        &.return {
          .tile {
            color: $info-color;
          }
        }
      }
    }

    .counter-bar {
      margin-top: $layout-spacing;
      &:first-child {
        margin-top: 0;
      }
      &.counter-1 .bar-item {
        background-color: $graphic-color-purple-2;
      }
      &.counter-2 .bar-item {
        background-color: $graphic-color-green-2;
      }
      &.counter-3 .bar-item {
        background-color: $graphic-color-blue-2;
      }
      &.counter-4 .bar-item {
        background-color: $graphic-color-pink-1;
      }
      &.counter-5 .bar-item {
        background-color: $graphic-color-orange-1;
      }
      &.counter-6 .bar-item {
        background-color: $graphic-color-purple-1;
      }
      &.counter-7 .bar-item {
        background-color: $graphic-color-green-3;
      }
      &.counter-8 .bar-item {
        background-color: $graphic-color-blue-3;
      }
      &.counter-9 .bar-item {
        background-color: $graphic-color-pink-1;
      }
      &.counter-10 .bar-item {
        background-color: $graphic-color-yellow-1;
      }
    }
  }
</style>
