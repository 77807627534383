<template>
  <div class="counter-bar">
    <div class="counter-bar-label">
      <span class="mr-1">{{ label }}</span>
      <span class="text-small text-gray">({{ value }})</span>
    </div>
    <st-bar :percent="percent" />
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
    },
    value: {
      type: Number,
    },
    label: {
      type: String,
    },
  },
  computed: {
    percent() {
      return Math.round((this.value / this.total) * 100);
    },
  },
};
</script>
