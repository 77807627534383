<template>
  <tr class="table-row">
    <td class="text-center hide-md hide-sm" width="45px">
      <dx-dropdown :items="[]" label="name"
                   class="attendance-status"
                   :disabled="true">
        <span class="square" :title="statusName"
              :style="{backgroundColor: statusColor}"></span>
      </dx-dropdown>
    </td>
    <td class="text-center" width="45px">
      <div v-if="item.appointment.expenses.length > 0">
        <span class="text-primary">
          {{ appointment.expenses[0].modality }}
        </span>
      </div>
    </td>
    <td class="text-center hide-md hide-sm" width="45px">
      <div class="svg-gender" v-if="patient.gender">
        <fa-icon
          :icon="['fal', 'male']"
          :class="patient.gender === 'male' ? 'male-color' : 'female-color'"
        ></fa-icon>
      </div>
    </td>
    <td class="text-ellipsis">
      <span
        class="hover-link"
        @click="openMedicalReport()"
      >{{ patient.name }}</span>
      <br>
      <small>{{ patient.birthDate | date }}</small>
      <small class="ml-1">({{ patient.birthDate | dateOld('short') }})</small>
      <small v-if="patient.dicomId"> - ID: {{ patient.dicomId }}</small>
    </td>
    <td class="text-ellipsis hide-md hide-sm">
      <div v-if="insurance">
        <span>{{ insurance.customName }}</span><br>
      </div>
      <small v-if="expenseNames.length">
        <span class="text-info">{{ expenseNames[0] }}</span>
        <span
          v-if="expenseNames.length > 1"
          class="label text-primary tooltip hover-link ml-1 "
          :data-tooltip="expenseNames.slice(1).join('\n')">
          +{{ expenseNames.length -1 }}
        </span>
      </small>
    </td>
    <td class="text-ellipsis hide-md hide-sm" width="17%">
      <small>
        Agendado: <strong>{{ item.appointment.date | date('DD/MM/YY HH:mm') }}</strong>
      </small><br>
      <small>AN: <strong>{{ item.appointment.tagDicomId }}</strong></small>
    </td>
    <td class="text-center" style="width:172px">
      <div
        class="dropdown dropdown-right mr-1 text-left"
        :class="{ active: viewer.menu }"
      >
        <a
          class="btn btn-sm btn-action btn-icon btn-gray"
          :class="{ loading: viewer.loading, tooltip: !viewer.loading, disabled: !viewer.enabled }"
          data-tooltip="Visualizador DICOM"
          @click="toggleViewerMenu"
        ><fa-icon :icon="['fal', 'eye']"></fa-icon></a>
        <ul class="menu" v-if="viewer.menu" v-click-outside="toggleViewerMenu">
          <li
            v-for="item in viewer.items"
            :key="item.type"
            class="menu-item"
          ><a :href="item.url" :target="item.target">{{ item.label }}</a></li>
        </ul>
      </div>
      <button
        class="btn btn-sm btn-gray btn-icon btn-action mr-1"
        :class="{ badge: hasAudio }"
        v-if="hasAudio">
        <fa-icon :icon="['fal', 'microphone']"></fa-icon>
      </button>
      <router-link
        :to="`/medical-reports/${this.item.id}`"
        class="btn btn-action btn-icon btn-gray btn-sm tooltip mr-1"
        data-tooltip="Laudo">
        <fa-icon :icon="['fal', 'file-medical-alt']"></fa-icon>
      </router-link>
      <button class="btn btn-action btn-icon btn-gray btn-sm tooltip mr-1"
              data-tooltip="Anexos" @click="attachment">
        <fa-icon :icon="['fal', 'paperclip']"></fa-icon>
      </button>
      <button class="btn btn-action btn-icon btn-gray btn-sm tooltip mr-1"
              data-tooltip="Histórico" @click="openHistory">
        <fa-icon :icon="['fal', 'history']"></fa-icon>
      </button>
      <router-link
        :to="`/patients/${this.item.appointment.patient.id}/edit`"
        class="btn btn-action btn-icon btn-gray btn-sm tooltip mr-1"
        data-tooltip="Paciente">
        <fa-icon :icon="['fal', 'user']"></fa-icon>
      </router-link>
      <button class="btn btn-sm btn-gray btn-icon btn-action"
              :disabled="printing || data.stage !== 'finished'"
              @click="print"
              :class="printing ? {loading: printing} : 'tooltip'"
              data-tooltip="Imprimir">
        <fa-icon :icon="['fal', 'print']"></fa-icon>
      </button>
    </td>
  </tr>
</template>

<script>
import { mergeFrom } from '@/helpers/object';
import * as statuses from '@/data/medical-record-statuses';
import { fetchViewerData } from '@/app/medical-report/components/helpers';

export default {
  props: {
    data: {
      id: {
        type: String,
      },
      stage: {
        type: String,
      },
      steps: {
        type: Array,
      },
      appointment: {
        id: {
          type: String,
        },
        tagDicomId: {
          type: String,
        },
        date: {
          type: Date,
        },
        schedule: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
        },
        professional: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
        },
        insurance: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
          customName: {
            type: String,
          },
        },
        patient: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
          birthDate: {
            type: Date,
          },
          gender: {
            type: String,
          },
          dicomId: {
            type: String,
          },
        },
        expenses: {
          type: Array,
        },
      },
      data: {
        id: {
          type: String,
        },
        inputs: {
          type: Array,
        },
      },
      audios: {
        type: Array,
        default: () => [],
      },
    },
  },
  data() {
    return {
      printing: false,
      statuses,
      viewer: {
        menu: false,
        enabled: true,
        loading: false,
        items: [],
      },
      item: this.blankItem(),
    };
  },
  mounted() {
    this.item = mergeFrom(this.blankItem(), this.data);
  },
  computed: {
    appointment() {
      return this.item.appointment;
    },
    patient() {
      return this.appointment.patient;
    },
    insurance() {
      return this.appointment.insurance;
    },
    expenseNames() {
      return this.appointment.expenses
        .map(expense => expense.name)
        .sort();
    },
    statusColor() {
      return statuses.getColor(this.data.stage);
    },
    statusName() {
      return statuses.getName(this.data.stage);
    },
    audiosQuantity() {
      return this.data.audios ? this.data.audios.length : 0;
    },
    hasAudio() {
      return this.audiosQuantity > 0;
    },
  },
  methods: {
    openMedicalReport() {
      this.$router
        .push(`/medical-reports/${this.item.id}`);
    },
    openHistory() {
      this.$emit('history', {
        patientId: this.data.appointment.patient.id,
      });
    },
    attachment() {
      this.$emit('attachment', {
        appointmentId: this.data.appointment.id,
        patientId: this.data.appointment.patient.id,
      });
    },
    print() {
      this.printing = true;

      return this.$fileX
        .print(`/medical-reports/${this.item.id}/print`)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
    async toggleViewerMenu() {
      if (!this.viewer.enabled) return;
      await this.loadViewerOptions();
      this.viewer.menu = this.viewer.items.length > 0 ? !this.viewer.menu : false;
    },
    async loadViewerOptions() {
      if (this.viewer.loading || this.viewer.items.length > 0) return;
      this.viewer.loading = true;
      try {
        const { items } = await fetchViewerData(this.item.id, (state) => {
          this.viewer.enabled = state !== 'locked';
        });
        this.viewer.items = items;
      } catch (e) {
        this.$toast.error(e);
      }
      this.viewer.loading = false;
    },
    blankItem() {
      return {
        id: '',
        stage: '',
        steps: [],
        appointment: {
          id: '',
          date: '',
          tagDicomId: '',
          schedule: {
            id: '',
            name: '',
          },
          professional: {
            id: '',
            name: '',
          },
          insurance: {
            id: '',
            name: '',
            customName: '',
          },
          patient: {
            id: '',
            name: '',
            birthDate: '',
            gender: '',
            dicomId: '',
          },
          expenses: [],
        },
        data: {
          id: '',
          inputs: [],
        },
      };
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/variables';
  .table-row {
    .attendance-status {
      &.dropdown > a {
        display: block;
        margin-top: .19rem;
        .square {
          height: .8rem;
          width: .8rem;
        }
      }
    }
    .svg-gender {
      font-size: .7rem;
    }
  }
</style>
