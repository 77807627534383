<template>
  <div class="hospitalized-content-page">
    <template v-if="canAccess">
      <div class="columns">
        <div class="column col-12 form-group mt-2" v-if="patient && patient.id">
          <button class="btn btn-warning btn-icon btn-icon-left"
                  :class="{loading: hospitalization.loading}"
                  :disabled="hospitalization.loading"
                  @click="openHospitalizationModal()">
            <fa-icon :icon="['fal', 'bed']" />
            <span class="mr-1">Internar paciente</span>
            <strong>{{ patient.name }}</strong>
          </button>
        </div>
        <div class="column col-3 form-group">
          <label class="form-label">Status</label>
          <select v-model="filter.status" class="form-select">
            <option value="open">Somente internados</option>
            <option value="finished">Internamentos com alta</option>
            <option value="">Todos</option>
          </select>
        </div>
        <div class="column form-group">
          <label for="search" class="form-label">Pesquisar</label>
          <div class="input-group">
            <input type="text" class="form-input" id="search"
                   v-model="filter.search" placeholder="Pesquisar paciente...">
            <button class="btn btn-gray btn-icon btn-action input-group-btn"
                    @click="filter.search = ''" v-if="filter.search !== ''">
              <fa-icon :icon="['fal', 'times']"/>
            </button>
            <button class="btn btn-neutral btn-icon btn-action input-group-btn" v-else>
              <fa-icon :icon="['fal', 'search']"/>
            </button>
          </div>
        </div>
        <div class="column col-auto col-sm-12 form-group mb-2"
             style="align-self: flex-end">
          <button class="btn btn-icon btn-icon-left btn-primary"
                  @click="openHospitalizationModal()">
            <fa-icon :icon="['fal', 'plus']"/>
            Novo internamento
          </button>
        </div>
      </div>

      <div class="loading loading-lg mt-2" v-if="loading" />
      <template v-else>
        <div class="empty" v-if="items.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'calendar-alt']" size="3x"/>
          </div>
          <p class="empty-title h5">Internados</p>
          <p class="empty-subtitle">Não há pacientes internados nesta unidade</p>
          <button class="btn btn-primary btn-icon btn-icon-left mt-2"
                  @click="openHospitalizationModal()">
            <fa-icon :icon="['fal', 'plus']"/>
            Novo internamento
          </button>
        </div>
        <template v-else>
          <table class="table table-hover">
            <tbody>
            <table-row v-for="(item, i) in items"
                       :data="item"
                       :key="i"
                       @edit="openHospitalizationModal(item.id)"
            />
            </tbody>
          </table>
          <div class="mt-2">
            Total de internados:
            <span class="text-primary text-bold">{{ items.length }}</span>
          </div>
        </template>
      </template>

      <hospitalization-modal
        v-if="hospitalization.show"
        :show="hospitalization.show"
        :id="hospitalization.id"
        :patient-id="hospitalization.patientId"
        @close="closeHospitalizationModal"
      />
    </template>
    <forbidden v-else />
  </div>
</template>

<script>
import Forbidden from '@/components/auth/Forbidden.vue';
import { LIST_HOSPITALIZATIONS } from '@/data/actions/modules/hospitalization';
import HospitalizationModal from './modals/Hospitalization.vue';
import TableRow from './HospitalizedTableRow.vue';

export default {
  props: {
    patient: {
      id: {
        type: String,
      },
      name: {
        type: String,
      },
      birthDate: {
        type: Date,
      },
      gender: {
        type: String,
      },
    },
  },
  components: {
    Forbidden,
    HospitalizationModal,
    TableRow,
  },
  data() {
    return {
      filter: {
        status: 'open',
        search: '',
      },
      loading: false,
      items: [],
      hospitalization: {
        id: '',
        patientId: '',
        show: false,
        loading: false,
      },
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    canAccess() {
      if (LIST_HOSPITALIZATIONS) {
        return this.$can(LIST_HOSPITALIZATIONS);
      }
      return true;
    },
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.load, 800);
      },
      deep: true,
    },
  },
  methods: {
    load() {
      this.loading = true;

      const params = {};

      if (this.filter.status) {
        params.status = this.filter.status;
      }

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      return this.$http.get('/hospitalizations', { params })
        .then(({ data }) => {
          this.items = data.items;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    openHospitalizationModal(id = null) {
      this.hospitalization.id = id || '';
      this.hospitalization.patientId = '';
      this.hospitalization.show = true;
    },
    closeHospitalizationModal() {
      this.load();
      this.hospitalization.show = false;
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/variables';
  .hospitalized-content-page {
    table {
      background-color: #fff;
      margin-top: $layout-spacing;
      table-layout: fixed;
      tr {
        td {
          border: .05rem solid $border-color;
        }
      }
    }
  }
</style>
