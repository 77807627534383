<template>
  <div class="patient-report-history">
    <div class="loading mt-2" v-if="loading"></div>
    <template v-else>
      <div class="empty mt-2" v-if="data.items.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Laudos</p>
        <p class="empty-subtitle">
          Nenhum laudo encontrado para este paciente.
        </p>
      </div>
      <table class="table table-striped table-hover" v-else>
        <thead>
        <tr>
          <th class="text-center" width="110px">Data do exame</th>
          <th>Exame</th>
          <th>Profissional</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in data.items" :key="i">
          <td class="text-center">{{ item.appointment.date | date }}</td>
          <td>
            <span v-if="item.appointment.expenses.length > 0">
              {{ item.appointment.expenses[0].name }}
            </span>
          </td>
          <td>{{ item.steps[item.steps.length -1].professional.name }}</td>
          <td class="text-right" width="70px">
            <button class="btn btn-sm btn-action btn-icon btn-gray"
                    :disabled="printing && idx === i"
                    :class="printing && idx === i ? {loading: printing} : 'tooltip'"
                    @click="print(item.id, i)" data-tooltip="Imprimir">
              <fa-icon :icon="['fal', 'print']"></fa-icon>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    patientId: {
      type: String,
    },
  },
  data() {
    return {
      path: '/medical-reports',
      loading: false,
      idx: null,
      printing: false,
      data: {
        items: [],
        total: 0,
        limit: 30,
        offset: 0,
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      const params = {
        limit: 0,
        patientId: this.patientId,
        history: true,
      };

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    print(id, i) {
      this.printing = true;
      this.idx = i;
      return this.$fileX
        .print(`/medical-reports/${id}/print`)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .patient-report-history {
  }
</style>
