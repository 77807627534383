import { httpX } from '@/plugins/http';
import { show as showToast } from '@/plugins/toast';

export async function fetchViewerData(reportId, handler = null) {
  handler = handler || (() => {});
  try {
    const { data } = await httpX.get(`/medical-reports/${reportId}/viewer`);
    data.items = data.items.map(item => ({
      ...item,
      target: /^https?:\/\//.test(item.url) ? '_blank' : '_self',
    }));
    return data;
  } catch (e) {
    if (e.response.status === 404) {
      showToast('Imagen não disponível', { type: 'warning' });
      handler('locked');
      setTimeout(() => {
        handler('ready');
      }, 1000 * 60);
    } else {
      showToast(e, { type: 'error' });
    }
  }
  return { items: [] };
}

export function normalizeImageUrl(url) {
  if (!url) return null;
  url += '?trim=true&f=jpeg';
  return url.startsWith('//') ? `https:${url}` : url;
}
export function normalizeImage(item) {
  if (item.url) {
    item.url = normalizeImageUrl(item.url);
  }
  return {
    id: null,
    url: null,
    date: null,
    file: null,
    dataURL: null,
    selected: true,
    capture: false,
    status: 'ready',
    ...item,
  };
}

export function normalizeImages(items) {
  if (!Array.isArray(items)) {
    items = [];
  }

  return items.map(normalizeImage);
}
