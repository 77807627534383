<template>
  <dx-modal title="Imprimir agendamentos" size="sm"
            :value="show" @input="close" id="modal-print-appointment">
    <div class="columns form-group">
      <div class="column col-6 col-sm-12 form-group"
           :class="{'has-error': $v.filter.startDate.$error}">
        <label class="form-label">Data inicial</label>
        <div class="input-group">
          <dx-input-date
            id="start-date"
            class="form-input text-center"
            v-model="filter.startDate"
            @blur="$v.filter.startDate.$touch()"
          />
        </div>
        <template v-if="$v.filter.startDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filter.startDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-if="!$v.filter.startDate.date">Data inválida</div>
        </template>
      </div>
      <div class="column col-6 col-sm-12 form-group"
           :class="{'has-error': $v.filter.endDate.$error}">
        <label class="form-label">Data final</label>
        <div class="input-group">
          <dx-input-date
            id="end-date"
            class="form-input text-center"
            v-model="filter.endDate"
            @blur="$v.filter.endDate.$touch()"
          />
        </div>
        <template v-if="$v.filter.endDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filter.endDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filter.endDate.date">Data inválida</div>
          <div class="form-input-hint"
               v-else-if="!$v.filter.endDate.minDate">Data inválida</div>
        </template>
      </div>
      <div class="column col-6 form-group">
        <label class="form-label">Filtrar por</label>
        <select id="type" class="form-select" v-model="filter.dateType">
          <option value="startDate">Data do agendamento</option>
          <option value="createdAt">Data da inclusão</option>
        </select>
      </div>
      <div class="column col-6 form-group">
        <label class="form-label">Tipo</label>
        <select id="type" class="form-select" v-model="filter.type">
          <option value="">Todos os tipos</option>
          <option v-for="(text, value) in type.types"
                  :value="value" :key="value">{{ text }}</option>
        </select>
      </div>
      <div class="column col-12 form-group"
           :class="{'has-error': $v.filter.scheduleId.$error}">
        <label class="form-label">Agendas</label>
        <select id="schedule" class="form-select"
                v-model="filter.scheduleId"
                @blur="$v.filter.scheduleId.$touch()">
          <option value="">[Selecione]</option>
          <option v-for="(item, i) in schedules"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
        <template v-if="$v.filter.scheduleId.$error">
          <div class="form-input-hint"
               v-if="!$v.filter.scheduleId.required">Campo obrigatório</div>
        </template>
      </div>
    </div>
    <label class="form-label">Status</label>
    <div class="scroll-list-wrapper">
      <div class="scroll-list">
        <div class="list-header">
          <label class="form-checkbox">
            <input type="checkbox"
                   @change="selectAllStatuses"
                   :checked="getStatusSelected"
                   :indeterminate.prop="getStatusSelected === null">
            <i class="form-icon"></i>
          </label>
          <div class="list-title">Todos</div>
        </div>
        <table class="table table-hover">
          <tbody>
          <tr v-for="(item, i) in statuses" :key="i">
            <td width="30px">
              <label class="form-checkbox">
                <input type="checkbox" v-model="item.selected">
                <i class="form-icon"></i>
              </label>
            </td>
            <td>{{ item.name }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template slot="footer">
      <button class="btn btn-gray mr-1 btn-icon btn-icon-left"
              :class="{loading: generating}"
              :disabled="printing || generating"
              @click="print('sheet')">
        <fa-icon :icon="['fal', 'file-excel']" />Gerar planilha
      </button>
      <button class="btn btn-primary mr-1 btn-icon btn-icon-left"
              :class="{loading: printing}"
              :disabled="printing || generating"
              @click="print('pdf')">
        <fa-icon :icon="['fal', 'print']" />Imprimir
      </button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { statuses as appointmentStatuses } from '@/data/appointment-statuses';
import * as type from '@/data/appointment-types';
import { date, minDate } from '../../../../data/validators';

export default {
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      statuses: [],
      loading: false,
      printing: false,
      generating: false,
      schedules: [],
      type,
      printType: 'pdf',
      filter: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        dateType: 'startDate',
        scheduleId: '',
        status: '',
        type: '',
      },
    };
  },
  validations() {
    const rules = {
      filter: {
        scheduleId: { required },
        startDate: { required, date },
        endDate: { required, date },
      },
    };

    if (this.filter.startDate && moment(this.filter.startDate).isValid()) {
      rules.filter.endDate = {
        required,
        date,
        minDate: minDate(this.filter.startDate),
      };
    }

    return rules;
  },
  mounted() {
    this.loadSchedules();
    this.loadStatuses();
  },
  computed: {
    getStatusSelected() {
      if (!this.statuses || this.statuses.length === 0) {
        return false;
      }

      const selected = this.statuses.filter(item => item.selected);
      if (selected.length === 0) {
        return false;
      }
      if (selected.length === this.statuses.length) {
        return true;
      }
      return null;
    },
  },
  methods: {
    loadStatuses() {
      this.statuses = appointmentStatuses
        .filter(({ key }) => !['blocked', 'available'].includes(key))
        .map(item => ({
          key: item.key,
          name: item.name,
          selected: true,
        }));
    },
    selectAllStatuses(e) {
      this.statuses.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    close() {
      this.$emit('close');
    },
    loadSchedules() {
      const params = {
        limit: 0,
        offset: 0,
        active: true,
        isProfessional: true,
      };

      return this.$http
        .get('/schedules', { params })
        .then(({ data }) => {
          this.schedules = data.items;
          if (this.schedules.length === 1) {
            this.filter.scheduleId = this.schedules[0].id;
          }
        })
        .catch(() => {});
    },
    print(reportType) {
      const isSheet = reportType === 'sheet';

      this.$v.filter.$touch();
      if (this.$v.filter.$error) {
        return null;
      }

      const maxDuration = moment.duration(moment(this.filter.endDate)
        .diff(moment(this.filter.startDate))).asMonths();

      if (maxDuration >= 3) {
        this.$toast.show('Período máximo (3 meses)', { type: 'error' });
        return false;
      }

      if (isSheet) {
        this.generating = true;
      } else {
        this.printing = true;
      }

      const params = {
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
        reportType,
      };

      if (this.filter.type) {
        params.type = this.filter.type;
      }

      if (this.filter.dateType) {
        params.dateType = this.filter.dateType;
      }

      if (this.filter.scheduleId) {
        params.scheduleId = this.filter.scheduleId;
      }

      if (this.filter.insurancePlanId) {
        params.insurancePlanId = this.filter.insurancePlanId;
      }

      const statusItems = this.statuses
        .filter(({ selected }) => selected)
        .map(({ key }) => key);

      if (statusItems) {
        params.statuses = statusItems.join(',');
      }

      if (isSheet) {
        return this.$file
          .download('/appointments/print', params)
          .catch(() => {})
          .then(() => {
            this.generating = false;
          });
      }

      return this.$file
        .print('/appointments/print', params)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../assets/scss/variables';
  @import '~assets/scss/mixins';

  #modal-print-appointment {
    .list-header {
      align-items: center;
      border-bottom: $border-width solid $border-color;
      display: flex;
      padding-bottom: $layout-spacing;
      .list-title {
        flex: 1;
        font-weight: bold;
      }
    }
    .scroll-list-wrapper {
      border: $border-color solid $border-width;
      border-radius: $border-radius;
      flex-grow: 1;
      height: 150px;
      //margin-bottom: $layout-spacing-lg * 2;
      position: relative;
    }
    .scroll-list {
      bottom: 0;
      overflow-y: auto;
      padding: $layout-spacing $layout-spacing-lg;
      position: absolute;
      top: 0;
      width: 100%;
      @include scroll-bar();
      .table th, .table td {
        padding: 0;
      }
      .table td {
        border-bottom: none;
      }
    }
  }
</style>
