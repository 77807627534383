<template>
  <tr class="hospitalized-table-row">
    <td class="text-center hide-md hide-sm" style="width:45px">
      <dx-dropdown :items="[]" label="name"
                   class="status"
                   :disabled="true">
        <span class="square" :title="statusName"
              :style="{backgroundColor: statusColor}"></span>
      </dx-dropdown>
    </td>
    <td class="text-center hide-md hide-sm" style="width:45px">
      <div class="svg-gender" v-if="item.patient.gender">
        <fa-icon :icon="['fal', 'male']"
                 :class="item.patient.gender === 'male'
                 ? 'male-color' : 'female-color'" />
      </div>
    </td>
    <td class="text-ellipsis">
      <span class="hover-link" @click="openMedicalRecord(item)">
        {{ item.patient.name }}
      </span><br>
      <small>{{ item.patient.birthDate | dateOld(true) }}</small>
    </td>
    <td class="text-ellipsis hide-md hide-sm">
      {{ item.expense.name }} <br>
      <small>{{ item.expense.name }}</small>
    </td>
    <td class="text-ellipsis hide-md hide-sm">
      <small>
        Local: <strong>{{ item.place.name }} ({{ item.place.bed.name }})</strong>
      </small><br>
      <small>Médico: <strong>{{ item.professional.name }}</strong></small>
    </td>
    <td class="text-center" style="width:50px">
      <dx-dropdown :items="menuHospitalizationOptions" label="name"
                   :disabled="menuHospitalizationOptions.length === 0"
                   direction="right" class="actions">
        <button class="btn btn-icon btn-action btn-link text-dark">
          <fa-icon :icon="['fal', 'bars']"/>
        </button>
        <template v-slot:item="{ item }">
          <div @click="menuAction(item, data)">
            <fa-icon class="ml-1" :icon="item.icon"/>
            <span class="ml-2">{{ item.name }}</span>
          </div>
        </template>
      </dx-dropdown>
    </td>
  </tr>
</template>

<script>
import * as statuses from '@/data/hospitalization-statuses';
import * as menuOptions from 'src/data/hospitalization-menu-options';

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      item: this.data,
      statuses,
      menuOptions,
    };
  },
  computed: {
    statusColor() {
      return statuses.getColor(this.item.status);
    },
    statusName() {
      return statuses.getName(this.item.status);
    },
    menuHospitalizationOptions() {
      const { options } = this.menuOptions;
      if (options.length > 0 && options[0] === '-') {
        options.shift();
      }
      return options;
    },
  },
  methods: {
    openMedicalRecord(item) {
      const patientId = item.patient.id;
      this.$router.push(`/patients/${patientId}/medical-records`);
    },
    menuAction(menu, data) {
      switch (menu.type) {
        case 'medical_record': {
          this.openMedicalRecord(data);
          break;
        }
        case 'hospitalization': {
          this.$emit('edit', data.id);
          break;
        }
        case 'therapeutic_plan': {
          this.$router.push(`/hospitalization/${data.id}/therapeutic-plan`);
          break;
        }
        default: {
          break;
        }
      }
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/variables';
  .hospitalized-table-row {
    .status {
      &.dropdown > a {
        display: block;
        margin-top: .19rem;
        .square {
          height: .8rem;
          width: .8rem;
        }
      }
    }
    .svg-gender {
      font-size: .7rem;
    }
    .actions {
      margin-top: .2rem;
      color: $dark-color;
      .menu {
        a {
          display: flex;
          .square {
            margin-right: $layout-spacing;
          }
        }
      }
      .menu-item {
        padding: $layout-spacing-sm;
        text-align: left;
        .divider {
          margin: 0;
        }
        &:hover {
          background-color: $secondary-color;
          border-radius: $border-radius;
          color: $light-color;
          cursor: pointer;
        }
      }
    }
  }
</style>
