<template>
  <div class="exam-content-page">
    <template v-if="canAccess">
<!--      <div class="form-group status-toggle" v-if="canAccessAll">-->
<!--        <label class="form-switch">-->
<!--          <input type="checkbox" v-model="filter.showAllProfessionalReports">-->
<!--          <i class="form-icon"></i>Visualizar laudos de todos os profissionais-->
<!--        </label>-->
<!--      </div>-->
      <div class="columns form-group">
        <div class="column col-6 col-md-12 col-sm-12 form-group">
          <label class="form-label">Status</label>
          <div class="btn-group btn-group-block">
            <button class="btn btn-icon-left"
                    :class="statusColor.available ? 'btn-primary' : 'btn-neutral'"
                    @click="setStatus('available')">
              Não assinado
            </button>
            <button class="btn btn-icon-left"
                    :class="statusColor.report ? 'btn-primary' : 'btn-neutral'"
                    @click="setStatus('report')">
              Laudando
            </button>
            <button class="btn btn-icon-left"
                    :class="statusColor.review ? 'btn-primary' : 'btn-neutral'"
                    @click="setStatus('review')">
              Em revisão
            </button>
            <button class="btn btn-icon-left"
                    :class="statusColor.finished ? 'btn-primary' : 'btn-neutral'"
                    @click="setStatus('finished')">
              Assinado
            </button>
          </div>
        </div>
      </div>
      <div class="columns form-group">
        <div class="column col-2 col-md-12 col-sm-12 form-group">
          <label for="modality" class="form-label">Modalidade</label>
          <select class="form-select" id="modality"
                  v-model="filter.modality" :disabled="loading">
            <option value="">Todas</option>
            <option value="CR">CR</option>
            <option value="CT">CT</option>
            <option value="DR">DR</option>
            <option value="DX">DX</option>
            <option value="EC">EC</option>
            <option value="ECG">ECG</option>
            <option value="ES">ES</option>
            <option value="EUA">EUA</option>
            <option value="MA">MA</option>
            <option value="MG">MG</option>
            <option value="MR">MR</option>
            <option value="MS">MS</option>
            <option value="US">US</option>
            <option value="XA">XA</option>
          </select>
        </div>
        <div class="column col-4 col-md-12 col-sm-12 form-group"
             :class="{'has-error': $v.filter.startDate.$error || $v.filter.endDate.$error}">
          <label class="form-label">Período</label>
          <div class="input-group">
            <dx-dropdown
              class="input-group-addon"
              :items="periodOptions"
              label="text"
              @select="setPeriod"
              direction="left"
            ><fa-icon :icon="['fal', 'chevron-down']" /></dx-dropdown>
            <dx-input-date
              id="start-date"
              class="form-input text-center"
              v-model="filter.startDate"
              @change="changedStartDate"
              @blur="$v.filter.startDate.$touch()"
            />
            <dx-input-date
              id="end-date"
              class="form-input text-center"
              v-model="filter.endDate"
              @change="changedEndDate"
              @blur="$v.filter.endDate.$touch()"
            />
          </div>
        </div>
        <div class="column form-group">
          <label for="search" class="form-label">Nome do paciente</label>
          <div class="input-group">
            <input type="text" class="form-input" id="search"
                   v-model="filter.search" placeholder="Pesquisar paciente...">
            <button class="btn btn-gray btn-icon btn-action input-group-btn"
                    @click="filter.search = ''" v-if="filter.search !== ''">
              <fa-icon :icon="['fal', 'times']"/>
            </button>
            <button class="btn btn-neutral btn-icon btn-action input-group-btn" v-else>
              <fa-icon :icon="['fal', 'search']"/>
            </button>
          </div>
        </div>
        <div class="column col-auto col-sm-12 form-group mb-2"
             style="align-self: flex-end">
          <button class="btn btn-icon btn-icon-left btn-primary"
                  @click="load">
            <fa-icon :icon="['fal', 'search']"/>
            Pesquisar
          </button>
        </div>
      </div>

      <template v-if="!loading">
        <div class="empty" v-if="medicalReports.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'calendar-alt']" size="3x"/>
          </div>
          <p class="empty-title h5">Exames</p>
          <p class="empty-subtitle">Nenhum exame encontrado</p>
        </div>
        <template v-else>
          <table class="table table-hover">
            <tbody>
            <exam-table-row v-for="(medicalReport, i) in medicalReports"
                            :data="medicalReport"
                            :key="i"
                            @history="openHistoryModal"
                            @attachment="openAttachmentModal"
            />
            </tbody>
          </table>
          <div class="mt-2">
            Total de exames:
            <span class="text-primary text-bold">{{ medicalReports.length }}</span>
          </div>
        </template>
      </template>

      <div class="loading loading-lg mt-2" v-else></div>

      <dx-modal title="Anexos do paciente"
                id="modal-attachment" size="md"
                v-if="modalAttachment.show"
                v-model="modalAttachment.show">
        <st-entity-file-manager
          :entity-id="modalAttachment.patientId"
          :origin-id="modalAttachment.appointmentId"
          origin-type="appointment"
          :readonly="true"
        />
        <template slot="footer">
          <button class="btn"
                  @click="modalAttachment.show = false">Sair</button>
        </template>
      </dx-modal>

      <dx-modal title="Laudos do paciente"
                id="modal-history" size="md"
                v-if="modalHistory.show"
                v-model="modalHistory.show">
        <history-modal
          :patient-id="modalHistory.patientId"
        />
        <template slot="footer">
          <button class="btn"
                  @click="modalHistory.show = false">Sair</button>
        </template>
      </dx-modal>
    </template>
    <forbidden v-else />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import localforage from 'localforage';
import { required } from 'vuelidate/lib/validators';
import { clone } from '@/helpers/object';
import { parsePeriod } from '@/helpers/date';
import moment from 'moment';
import Forbidden from '@/components/auth/Forbidden.vue';
import { LIST_MEDICAL_REPORTS } from '@/data/actions/modules/clinical';
import ExamTableRow from './ExamTableRow.vue';
import HistoryModal from '../../medical-report/components/History.vue';
import { date, minDate } from '../../../data/validators';

export default {
  components: {
    ExamTableRow,
    HistoryModal,
    Forbidden,
  },
  data() {
    return {
      debounceTimeout: null,
      loading: false,
      statusColor: {
        available: true,
        report: true,
        review: false,
        finished: false,
      },
      filter: {
        scheduleId: '',
        showAllProfessionalReports: false,
        modality: '',
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        search: '',
      },
      modalAttachment: {
        show: false,
        patientId: '',
        appointmentId: '',
      },
      modalHistory: {
        show: false,
        patientId: '',
      },
      medicalReports: [],
      periodOptions: [
        { value: 'today', text: 'Hoje' },
        { value: 'week', text: 'Esta semana' },
        { value: 'd:-7', text: 'Últimos 7 dias' },
        { value: 'd:-15', text: 'Últimos 15 dias' },
        { value: 'month', text: 'Este mês' },
      ],
    };
  },
  mounted() {
    this.loading = true;
    this.loadFilterState()
      .then(this.load);
  },
  validations() {
    const rules = {
      filter: {
        startDate: { required, date },
        endDate: { required, date },
      },
    };

    if (this.filter.startDate && moment(this.filter.startDate).isValid()) {
      rules.filter.endDate = {
        required,
        date,
        minDate: minDate(this.filter.startDate),
      };
    }

    return rules;
  },
  computed: {
    ...mapGetters({
      isCollaborator: 'isCollaborator',
    }),
    ...mapState({
      user: ({ auth }) => auth.user,
    }),
    canAccess() {
      if (LIST_MEDICAL_REPORTS) {
        return this.$can(LIST_MEDICAL_REPORTS);
      }
      return true;
    },
    filterProfessional() {
      return this.user.branch
        && this.user.branch.settings
        && this.user.branch.settings['attendance.medicalReport'];
    },
    // canAccessAll() {
    //   return this.isCollaborator;
    // },
  },
  methods: {
    loadFilterState() {
      return localforage.getItem('attendanceExamFilters')
        .then((filters) => {
          if (filters && filters.modality) {
            this.filter.modality = filters.modality;
          }
          if (filters && filters.scheduleId) {
            this.filter.scheduleId = filters.scheduleId;
          }
          if (filters && filters.showAllProfessionalReports) {
            this.filter.showAllProfessionalReports = filters.showAllProfessionalReports;
          }
          if (filters && filters.search) {
            this.filter.search = filters.search;
          }
        })
        .catch(() => {});
    },
    setStatus(status) {
      if (status === 'available') {
        this.statusColor.available = !this.statusColor.available;
      } if (status === 'report') {
        this.statusColor.report = !this.statusColor.report;
      } if (status === 'review') {
        this.statusColor.review = !this.statusColor.review;
      } if (status === 'finished') {
        this.statusColor.finished = !this.statusColor.finished;
      }
      this.load();
    },
    load() {
      const stage = ['screening'];

      if (this.statusColor.available) {
        stage.push('available');
      } if (this.statusColor.report) {
        stage.push('report');
      } if (this.statusColor.review) {
        stage.push('review');
      } if (this.statusColor.finished) {
        stage.push('finished');
      }

      if (stage.length === 0) {
        this.$toast.show('Selecione um ou mais status', { type: 'error' });
        this.medicalReports = [];
        return null;
      }

      const startDate = moment(this.filter.startDate, 'YYYY-MM-DD');
      const endDate = moment(this.filter.endDate, 'YYYY-MM-DD');
      const diff = endDate.diff(startDate, 'days');

      if (diff > 60) {
        this.$toast.show('Período máximo (60 dias)', { type: 'error' });
        this.medicalReports = [];
        return null;
      }

      this.loading = true;

      const filters = clone(this.filter);
      filters.search = '';
      localforage.setItem('attendanceExamFilters', filters);

      const params = {
        limit: 100,
      };

      if (this.filterProfessional) {
        params.professionalId = this.user.id;
      }

      if (stage.length > 0) {
        params.stage = stage.join(',');
      }

      if (this.filter.modality) {
        params.modality = this.filter.modality;
      }

      if (this.filter.startDate) {
        params.startDate = this.filter.startDate;
      }

      if (this.filter.endDate) {
        params.endDate = this.filter.endDate;
      }

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      return this.$httpX.get('/medical-reports', { params })
        .then(({ data }) => {
          this.medicalReports = data.items;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    setPeriod({ value }) {
      const { start, end } = parsePeriod(value);
      this.filter.startDate = start;
      this.filter.endDate = end;
    },
    openHistoryModal(data) {
      this.modalHistory.patientId = data.patientId;
      this.modalHistory.show = true;
    },
    openAttachmentModal(data) {
      this.modalAttachment.patientId = data.patientId;
      this.modalAttachment.appointmentId = data.appointmentId;
      this.modalAttachment.show = true;
    },
    changedStartDate() {
      if (!this.filter.startDate) {
        this.filter.startDate = moment().format('YYYY-MM-DD');
        this.filter.endDate = moment().format('YYYY-MM-DD');
      }
    },
    changedEndDate() {
      if (!this.filter.startDate && !this.filter.endDate) {
        this.filter.endDate = moment().format('YYYY-MM-DD');
        this.filter.endDate = moment().format('YYYY-MM-DD');
      }
      if (!this.filter.endDate) {
        this.filter.endDate = this.filter.startDate;
      }
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/variables';
  .exam-content-page {
    table {
      background-color: #fff;
      margin-top: $layout-spacing;
      table-layout: fixed;
      tr {
        td {
          border: .05rem solid $border-color;
        }
      }
    }
  }
</style>
