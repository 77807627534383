<template>
  <div>
    <dx-modal id="modal-patient-history" title="Histórico de laudos" size="md"
              :value="show" @input="close">
      <div class="loading loading-lg" v-if="loading" />
      <div v-else>
        <div class="empty" v-if="data.items.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Histórico de laudos</p>
          <p class="empty-subtitle">Nenhum laudo informado para este paciente</p>
        </div>
        <template v-else>
          <input type="text" class="form-input text-bold mb-2"
                 :value="data.items[0].appointment.patient.name" readonly>
          <table class="table table-striped table-hover">
            <thead>
            <tr>
              <th>Data</th>
              <th>Exame</th>
              <th>Profissional</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in data.items" :key="i">
              <td>{{ item.appointment.date | date }}</td>
              <td class="text-ellipsis" style="max-width: 200px">
                <small v-if="item.appointment.expenses.length > 0">
                  {{ item.appointment.expenses[0].name }}
                </small>
              </td>
              <td><small>{{ item.steps[item.steps.length -1].professional.name }}</small></td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-gray"
                        :disabled="printing"
                        :class="printing ? {loading: printing && idx === i} : 'tooltip'"
                        @click="print(item.id, i)" data-tooltip="Imprimir">
                  <fa-icon :icon="['fal', 'print']"></fa-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <template slot="footer">
        <button class="btn btn-gray" @click="close">Fechar</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>

export default {
  props: {
    show: {
      type: Boolean,
    },
    patientId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      printing: false,
      idx: null,
      data: {
        limit: 0,
        offset: 0,
        items: [],
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;

      const params = {
        limit: 10,
        patientId: this.patientId,
      };

      this.$httpX.get('/medical-reports', { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit('close');
    },
    print(id, i) {
      this.printing = true;
      this.idx = i;
      return this.$fileX
        .print(`/medical-reports/${id}/print`)
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.printing = false;
        });
    },
  },
};
</script>
