export const types = {
  consultation: 'Consultas',
  procedure: 'Procedimentos',
  fees: 'Honorários',
  tax: 'Taxas',
  daily: 'Diárias',
  product: 'Materiais / Medicamentos',
  canteen: 'Cantina',
  surgery: 'Cirurgia',
  others: 'Outros',
};

export function getName(value) {
  return types[value];
}
