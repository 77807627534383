<template>
  <dx-modal title="Imprimir repasses realizados" size="sm"
            :value="show" @input="close" id="print-modal">
    <div class="columns">
      <div class="column col-6 col-md-12 col-sm-12 form-group"
           :class="{'has-error': $v.data.startDate.$error}">
        <label class="form-label">Data início</label>
        <div class="input-group">
          <dx-input-date
            id="appointment-patient-birth-date"
            class="form-input text-center"
            v-model="data.startDate"
            @blur="$v.data.startDate.$touch()"
          />
        </div>
        <template v-if="$v.data.startDate.$error">
          <div class="form-input-hint"
               v-if="!$v.data.startDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.data.startDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-6 col-md-12 col-sm-12 form-group"
           :class="{'has-error': $v.data.endDate.$error}">
        <label class="form-label">Data término</label>
        <dx-input-date
          id="end-date"
          class="form-input text-center"
          v-model="data.endDate"
          @blur="$v.data.endDate.$touch()"
        />
        <template v-if="$v.data.endDate.$error">
          <div class="form-input-hint"
               v-if="!$v.data.endDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.data.endDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-12 form-group">
        <label class="form-label">Profissional</label>
        <strong>{{ user.name }}</strong>
      </div>
    </div>
    <template slot="footer">
      <button class="btn btn-primary btn-icon-left mr-1"
              :class="{loading: printing}"
              :disabled="printing"
              @click="print">
        <fa-icon :icon="['fal', 'print']"/>
        Imprimir
      </button>
      <button class="btn btn-icon-left" @click="close">
        Fechar
      </button>
    </template>
  </dx-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      printing: false,
      data: {
        startDate: '',
        endDate: '',
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  validations() {
    const rules = {
      data: {
        startDate: { date, required },
        endDate: { date, required },
      },
    };
    if (this.data.startDate && this.data.startDate.length === 10) {
      rules.data.endDate = {
        date,
        required,
        minDate: minDate(this.data.startDate),
      };
    }
    return rules;
  },
  mounted() {
    this.$v.data.$reset();
    this.data.startDate = moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');
    this.data.endDate = moment()
      .subtract(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD');
  },
  methods: {
    print() {
      this.$v.data.$touch();
      if (this.$v.data.$error || this.printing) {
        return false;
      }

      const maxDuration = moment.duration(moment()
        .diff(moment(this.data.startDate))).asMonths();

      const duration = moment.duration(moment(this.data.endDate)
        .diff(moment(this.data.startDate))).asMonths();

      if (maxDuration >= 7) {
        this.$toast.show('Período máximo (6 meses)', { type: 'error' });
        return false;
      }

      if (duration > 3) {
        this.$toast.show('O intervalo entre as datas não pode ser superior a 3 meses!',
          { type: 'error' });
        return false;
      }

      this.printing = true;

      const params = {
        professionalId: this.user.id,
        group: 'professional',
        startDate: this.data.startDate,
        endDate: this.data.endDate,
        return: true,
        courtesy: true,
        statuses: 'finished',
      };

      return this.$file
        .print('/professional-transfer/print', params)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
