export const statuses = [
  {
    key: 'waiting',
    name: 'Não assinado',
    color: '#35d0ce',
  },
  {
    key: 'examined',
    name: 'Não assinado',
    color: '#35d0ce',
  },
  {
    key: 'screening',
    name: 'Pré-atendimento',
    color: '#B4FF00',
  },
  {
    key: 'report',
    name: 'Laudando',
    color: '#E676FF',
  },
  {
    key: 'review',
    name: 'Em revisão',
    color: '#ff9696',
  },
  {
    key: 'finished',
    name: 'Assinado',
    color: '#3197FF',
  },
  {
    key: 'canceled',
    name: 'Cancelado',
    color: '#f85c5c',
  },
];

export function get(key) {
  return statuses.find(item => item.key === key);
}

export function getColor(key) {
  const status = get(key);
  return status ? status.color : null;
}

export function getName(key) {
  const status = get(key);
  return status ? status.name : null;
}

export default statuses;
