export const reasons = {
  1.1: 'Alta Curado',
  1.2: 'Alta Melhorado',
  1.3: 'Alta da Puérpera e permanência do recém-nascido',
  1.4: 'Alta a Pedido',
  1.5: 'Alta com previsão de retorno para acompanhamento do paciente',
  1.6: 'Alta por Evasão',
  1.7: 'Alta da Puérpera e recém-nascido',
  1.8: 'Alta por Outros motivos',
  2.1: 'Por características próprias da doença',
  2.2: 'Por Intercorrência',
  2.3: 'Por impossibilidade sócio-familiar',
  2.6: 'Por mudança de Procedimento',
  2.7: 'Por reoperação',
  3.1: 'Transferido para outro estabelecimento',
  4.1: 'Com declaração de óbito fornecida pelo médico assistente',
  4.2: 'Com declaração de Óbito fornecida pelo Instituto Médico Legal - IML',
  2.8: 'Outros motivos',
};

export function getName(value) {
  return reasons[value];
}
