<template>
  <div class="hospitalization-expense">
    <div>
      <button class="btn btn-icon btn-icon-left mr-2"
              :class="filters.show ? 'btn-warning' : 'btn-info'"
              @click="openFilter">
        <fa-icon :icon="['fal', filters.show ? 'times' : 'filter']" />
        {{ filters.show ? 'Fechar' : 'Abrir' }} Filtros
      </button>
      <button class="btn btn-icon btn-icon-left"
              :class="expense.show ? 'btn-warning' : 'btn-primary'"
              @click="openExpense">
        <fa-icon :icon="['fal', expense.show ? 'times' : 'plus-circle']" />
        {{ expense.show ? 'Fechar' : 'Adicionar' }} Despesas
      </button>
      <button class="btn btn-icon btn-icon-left btn-gray float-right"
              @click="openDischarge">
        <fa-icon :icon="['fal', 'sack-dollar']" />
        Realizar alta do paciente
      </button>
    </div>
    <div class="divider"></div>
    <div class="group-items" v-if="filters.show">
      <div class="form-group text-bold">Filtros</div>
      <div class="columns">
        <div class="column col-2 col-md-12 col-sm-12 form-group">
          <label class="form-label">Tipo</label>
          <select id="filter-type" class="form-select"
                  v-model="filters.type">
            <option value="">Todos os status</option>
            <option v-for="(text, value) in type.types"
                    :value="value" :key="value">{{ text }}</option>
          </select>
        </div>
        <div class="column col-2 col-md-12 col-sm-12 form-group">
          <label class="form-label">Status</label>
          <select id="filter-status" class="form-select"
                  v-model="filters.status">
            <option value="">Todos os status</option>
            <option v-for="(text, value) in status.statuses"
                    :value="value" :key="value">{{ text }}</option>
          </select>
        </div>
        <div class="column col-3 col-md-12 col-sm-12 form-group">
          <label class="form-label">Convênio</label>
          <select id="filter-insurance" class="form-select"
                  v-model="filters.insurancePlanId">
            <option value="">Todos os convênios</option>
            <option v-for="(item, i) in insurances"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
        </div>
        <div class="column form-group">
          <label class="form-label">Pesquisar</label>
          <div class="input-group">
            <input type="text" id="filter-search" class="form-input"
                   v-model="filters.search"
                   placeholder="Nome ou parte do nome da despesa...">
            <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                    tabindex="-1">
              <fa-icon :icon="['fal', 'search']"/>
            </button>
          </div>
        </div>
        <div class="column col-auto mb-2 col-sm-12 form-group"
             style="display: flex; align-items: flex-end">
          <button class="btn btn-primary btn-icon btn-icon-left btn-block"
                  :class="{loading}"
                  :disabled="loading"
                  @click="load">
            <fa-icon :icon="['fal', 'filter']"/>Filtrar
          </button>
        </div>
      </div>
    </div>
    <div class="group-items" v-if="expense.show">
      <div class="form-group text-bold">Adicionar despensa</div>
      <div class="columns">
        <div class="column col-5 form-group"
             :class="{'has-error': $v.expense.data.insurancePlanId.$error}">
          <label class="form-label">Convênio</label>
          <select id="expense-insurance" class="form-select"
                  v-model="expense.data.insurancePlanId"
                  :disabled="hasExpense"
                  @blur="$v.expense.data.insurancePlanId.$touch()">
            <option v-for="(item, i) in filteredInsurances"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
        </div>
        <div class="column col-7 form-group">
          <label class="form-label">Profissional</label>
          <select id="expense-professional" class="form-select"
                  v-model="expense.data.professionalId"
                  :disabled="hasExpense">
            <option value="">[Selecione um profissional]</option>
            <option v-for="(item, i) in professionals"
                    :value="item.id" :key="i">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="column form-group"
             :class="{'has-error': $v.expense.data.id.$error}">
          <label class="form-label">Despesa</label>
          <dx-autocomplete
            id="expense-item"
            v-model="expense.item"
            :source="findExpense"
            :readonly="!!expense.data.id"
            track-by="id"
            :custom-label="setExpenseName"
            @input="setExpense"
            @blur="$v.expense.data.id.$touch()"
            placeholder="Informe o código ou nome da despesa..."
            :debounce="800"
            input-id="expense-item">
            <button slot="action"
                    v-if="!!expense.data.id"
                    class="btn btn-action input-group-btn btn-icon btn-gray"
                    @click="clearExpense"
                    tabindex="-1">
              <fa-icon :icon="['fal', 'times']"></fa-icon>
            </button>
            <button slot="action" v-else
                    class="btn btn-action input-group-btn btn-icon btn-neutral"
                    tabindex="-1">
              <fa-icon :icon="['fal', 'search']"></fa-icon>
            </button>
            <template v-slot="{ item }">
              <a>
                <fa-icon :icon="['fal', 'file-medical-alt']" class="text-primary"/>
                <span class="ml-1">{{ item.code | tuss }} - {{ item.name }}
                  <span v-if="item.professional && item.professional.name"> ({{
                      item.professional.name }})
                  </span>
                </span>
              </a>
            </template>
          </dx-autocomplete>
        </div>
        <div class="column col-2 form-group"
             :class="{'has-error': $v.expense.data.quantity.$error}">
          <label class="form-label">Quantidade</label>
          <dx-input-number class="form-input" :precision="3"
                           v-model="expense.data.quantity"
                           @blur="$v.expense.data.quantity.$touch()"
          />
        </div>
        <div class="column col-2 form-group">
          <label class="form-label">Total</label>
          <dx-input-number class="form-input" :precision="2"
                           v-model="total"
                           readonly="true"
          />
        </div>
        <div class="column col-auto form-group mb-2"
             style="display: flex; align-items: flex-end">
          <button class="btn btn-icon btn-icon-left btn-primary"
                  :class="{loading: expense.saving}"
                  :disabled="expense.saving"
                  @click="addExpense">
            <fa-icon :icon="['fal', 'plus']" />Adicionar
          </button>
        </div>
      </div>
    </div>
    <div class="empty" v-if="!hasExpenses">
      <div class="empty-icon">
        <fa-icon :icon="['fal', 'file-invoice-dollar']" size="3x"></fa-icon>
      </div>
      <p class="empty-title h5">Despesas</p>
      <p class="empty-subtitle">
        Nenhuma despesa encontrada. Verifique os filtros e tente novamente
      </p>
    </div>
    <template v-else>
      <div class="h6">Despesas</div>
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th>#</th>
          <th>Data / Status</th>
          <th>Tipo / Despesa</th>
          <th>Convênio / Profissional</th>
          <th class="text-center">Quantidade</th>
          <th class="text-right">Total</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in items" :key="i">
          <td>{{ i + 1 }}</td>
          <td>
            {{ item.date | date('datetime') }}<br>
            <strong :class="item.status === 'open' ? 'text-warning' : 'text-info'">
              {{ status.getName(item.status) }}
            </strong>
          </td>
          <td>
            {{ type.getName(item.type) }}
            <div class="text-bold text-primary">
              <span v-if="item.expense.code">{{ item.expense.code | tuss }} - </span>
              {{ item.expense.name }}
            </div>
          </td>
          <td>
            {{ item.insurance.name }}
            <div>{{ item.professional ? item.professional.name : '-' }}</div>
          </td>
          <td class="text-center">{{ item.quantity }}</td>
          <td class="text-right">{{ item.particularValue * item.quantity | currency }}</td>
          <td class="text-right">
            <button class="btn btn-icon btn-action btn-sm btn-gray"
                    :disabled="expense.deleting"
                    @click="removeExpense(item.id, i)">
              <fa-icon :icon="['fal', 'times']" />
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
    <hospital-discharge
      v-if="discharge.show"
      :show="discharge.show"
      :id="id"
      @close="closeDischarge"
    />
  </div>
</template>

<script>
import * as status from 'src/data/patient-account-item-statuses';
import { required } from 'vuelidate/lib/validators';
import * as type from 'src/data/patient-account-item-types';
import * as professionalApi from '@/app/professional/api';
import HospitalDischarge from './HospitalDischarge.vue';

export default {
  props: {
    id: {
      type: String,
    },
    insurancePlanId: {
      type: String,
    },
  },
  components: {
    HospitalDischarge,
  },
  data() {
    return {
      status,
      type,
      insurances: [],
      professionals: [],
      loading: false,
      filters: {
        show: false,
        type: '',
        status: '',
        insurancePlanId: '',
        search: '',
      },
      expense: {
        show: false,
        saving: false,
        item: null,
        data: {
          id: '',
          name: '',
          insurancePlanId: '',
          professionalId: '',
          quantity: '',
          insuranceValue: '',
          particularValue: '',
        },
      },
      discharge: {
        show: false,
      },
      items: [],
    };
  },
  mounted() {
    this.loadInsurances();
    this.loadProfessionals();
    this.load();
    this.expense.data.insurancePlanId = this.insurancePlanId;
  },
  computed: {
    filteredInsurances() {
      return this.insurances
        .filter((item) => {
          if (item.type === 'particular') {
            return true;
          }
          if (this.insurancePlanId) {
            return this.insurancePlanId === item.id;
          }
          return false;
        });
    },
    hasExpenses() {
      return this.items.length > 0;
    },
    total() {
      return this.expense.data.particularValue * this.expense.data.quantity;
    },
    hasExpense() {
      return this.expense.item && this.expense.item.id;
    },
  },
  validations() {
    return {
      expense: {
        data: {
          id: { required },
          insurancePlanId: { required },
          quantity: { required },
        },
      },
    };
  },
  methods: {
    load() {
      this.loading = true;

      this.$http.get(`/hospitalizations/${this.id}/expenses`)
        .then(({ data }) => {
          this.items = data.items;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.insurances = data.items.map(item => ({
            id: item.plan.id,
            type: item.type,
            name: item.customName,
          }));
        })
        .catch(() => {});
    },
    loadProfessionals() {
      return professionalApi.allActive()
        .then((data) => {
          this.professionals = data.items;
        })
        .catch(this.$toast.error);
    },
    findExpense(search) {
      const params = {
        search,
        planId: this.expense.data.insurancePlanId,
        limit: 30,
        offset: 0,
      };

      if (this.expense.data.professionalId) {
        params.professionalId = this.expense.data.professionalId;
      }

      return this.$http.get('/expense-insurances', { params })
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{
              id: null,
              name: 'Despesa não encontrado neste convênio',
            }];
          }
          return data.items;
        });
    },
    setExpenseName(item) {
      const professional = item.professional && item.professional.name
        ? item.professional.name
        : null;
      return `${item.name} (${professional})`;
    },
    setExpense(data) {
      if (data) {
        this.expense.data.id = data.id;
        this.expense.data.name = data.name;
        this.expense.data.quantity = 1;
        this.expense.data.insuranceValue = data.values.insurance;
        this.expense.data.particularValue = data.values.particular;
        this.expense.data.professionalId = data.professional ? data.professional.id : '';
      }
    },
    clearExpense() {
      this.expense.item = null;
      this.expense.data.id = '';
      this.expense.data.professionalId = '';
      this.expense.data.name = '';
      this.expense.data.quantity = '';
      this.expense.data.insuranceValue = '';
      this.expense.data.particularValue = '';
      this.$v.expense.data.$reset();
    },
    addExpense() {
      this.$v.expense.data.$touch();
      if (this.$v.expense.data.$error) {
        return;
      }

      this.expense.saving = true;

      const data = {
        expenseId: this.expense.data.id,
        insurancePlanId: this.expense.data.insurancePlanId,
        quantity: this.expense.data.quantity,
      };

      if (this.expense.data.professionalId) {
        data.professionalId = this.expense.data.professionalId;
      }

      this.$http.post(`/hospitalizations/${this.id}/expenses`, data)
        .then(({ data: result }) => {
          this.items = result.items;
          this.clearExpense();
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.expense.saving = false;
        });
    },
    clearFilter() {
      this.filters.type = '';
      this.filters.status = '';
      this.filters.insurancePlanId = '';
      this.filters.search = '';
    },
    openFilter() {
      this.clearFilter();
      this.clearExpense();
      this.expense.show = false;
      this.filters.show = !this.filters.show;
    },
    openExpense() {
      this.clearExpense();
      this.clearFilter();
      this.filters.show = false;
      this.expense.show = !this.expense.show;
    },
    removeExpense(id, i) {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este registro?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.$http.delete(`/hospitalizations/${this.id}/expenses/${id}`)
                .then(() => {
                  this.items.splice(i, 1);
                })
                .catch(() => {
                  this.$toast.show('Ocorreu um erro. Tente novamente!', { type: 'error' });
                })
                .finally(() => {
                  this.expense.deleting = false;
                  close();
                });
            },
          },
        ],
      });
    },
    openDischarge() {
      this.clearExpense();
      this.clearFilter();
      this.filters.show = false;
      this.expense.show = false;
      this.discharge.show = true;
    },
    closeDischarge(data) {
      if (data && data.finished) {
        this.$emit('finished');
      }
      this.discharge.show = false;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
.hospitalization-expense {
  padding-top: $layout-spacing-sm;
  .group-items {
    background-color: $gray-color-ultra-light;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin: $layout-spacing 0;
    padding: $layout-spacing;
  }
}
</style>
