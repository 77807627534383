export const statuses = [
  {
    key: 'open',
    name: 'Internado',
    color: '#35d0ce',
  },
  {
    key: 'finished',
    name: 'Em alta',
    color: '#3197FF',
  },
];

export function get(key) {
  return statuses.find(item => item.key === key);
}

export function getColor(key) {
  const status = get(key);
  return status ? status.color : null;
}

export function getName(key) {
  const status = get(key);
  return status ? status.name : null;
}

export default statuses;
