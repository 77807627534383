<template>
  <dx-modal title="Alta do paciente" size="md"
            :value="show" @input="close"
            id="modal-hospital-discharge">
    <div class="loading loading-lg" v-if="loading" />
    <template v-else>
      <div class="columns">
        <div class="column col-3 form-group">
          <small class="text-bold">Data do internamento</small>
          <div>{{ form.startDate | date('datetime') }}</div>
        </div>
        <div class="column col-6 form-group">
          <small class="text-bold">Local</small>
          <div>{{ form.place.name }} ({{ form.place.bed.name }})</div>
        </div>
      </div>
      <div class="divider" />
      <div class="columns">
        <div class="column col-6 form-group">
          <small class="text-bold">Nome do paciente</small>
          <div class="text-bold text-primary">
            <fa-icon :icon="['fal', 'male']"
                     :class="form.patient.gender === 'male'
                     ? 'male-color' : 'female-color'" />
            {{ form.patient.name.toUpperCase() }}
          </div>
        </div>
        <div class="column col-6 form-group">
          <small class="text-bold">Data de nascimento (idade)</small>
          <div>
            {{ form.patient.birthDate | date }}
            <small>({{ form.patient.birthDate | dateOld }})</small>
          </div>
        </div>
      </div>
      <div class="divider" />
      <div class="card-cashier">
        <div class="columns">
          <div class="column">
            <small class="text-bold">Total particular</small>
            <div class="card-price">{{ totalParticular | currency }}</div>
          </div>
          <div class="column centered text-right" v-if="totalParticular > 0">
            <button class="btn btn-icon btn-icon-left btn-warning">
              <fa-icon :icon="['fal', 'sack-dollar']" />
              Realizar pagamento
            </button>
          </div>
        </div>
      </div>
      <div class="divider" />
      <div class="columns">
        <div class="column col-12 form-group"
             :class="{'has-error': $v.form.notes.$error}">
          <label class="form-label">Informações da alta</label>
          <textarea id="hospital-discharge-notes" class="form-input"
                    v-model="form.notes" @blur="$v.form.notes.$touch()"
                    rows="3" placeholder="Informações adicionais sobre a alta..."/>
          <template v-if="$v.form.notes.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.notes.required">Campo obrigatório</div>
          </template>
        </div>
        <div class="column col-12 form-group"
             :class="{'has-error': $v.form.reason.$error}">
          <label class="form-label">Motivo da alta</label>
          <select id="hospital-discharge-reason" class="form-select"
                  v-model="form.reason" @blur="$v.form.reason.$touch()">
            <option value="">[Selecione]</option>
            <option v-for="(text, value) in reasons"
                    :value="value" :key="value">{{ text }}</option>
          </select>
          <template v-if="$v.form.reason.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.reason.required">Campo obrigatório</div>
          </template>
        </div>
      </div>
    </template>
    <template slot="footer">
      <button class="btn btn-icon btn-icon-left btn-primary mr-1"
              :class="{loading: saving}"
              :disabled="loading || saving"
              @click="save">
        <fa-icon :icon="['fal', 'upload']" />
        Realizar alta
      </button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import formMixin from 'src/mixins/form';
import { mergeFrom } from 'src/helpers/object';
import { required } from 'vuelidate/src/validators';
import { reasons } from '../../../../data/hospital-discharge-reasons';

export default {
  props: {
    id: {
      type: String,
    },
    show: {
      type: Boolean,
    },
  },
  mixins: [
    formMixin,
  ],
  data() {
    return {
      loading: false,
      saving: false,
      reasons,
      form: this.formBlank(),
    };
  },
  validations() {
    return {
      form: {
        reason: { required },
        notes: { required },
      },
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    totalParticular() {
      return this.form.items.reduce((a, b) => a + (b.particularValue * b.quantity), 0);
    },
  },
  methods: {
    load() {
      this.loading = true;
      return this.$http
        .get(`/hospitalizations/${this.id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.formBlank(), data);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit('close');
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return null;
      }

      this.saving = true;

      const data = {
        reason: `${this.form.reason} - ${this.reasons[this.form.reason]}`,
        notes: this.form.notes,
      };

      return this.$http.post(`/hospitalizations/${this.id}/discharge`, data)
        .then(() => {
          this.$toast.show('Alta realizada com sucesso!');
          this.$emit('close', { finished: true });
        })
        .catch(() => {
          this.$toast
            .show('Ocorreu um erro ao realizar a alta do paciente. Tente novamente!',
              { type: 'error' });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    formBlank() {
      return {
        id: '',
        status: 'open',
        startDate: '',
        endDate: '',
        place: {
          id: '',
          name: '',
          date: '',
          bed: {
            id: '',
            name: '',
            accommodation: {
              id: '',
              name: '',
            },
          },
        },
        expense: {
          id: '',
          name: '',
        },
        professional: {
          id: '',
          name: '',
        },
        patient: {
          id: '',
          name: '',
          birthDate: '',
          gender: '',
          identity: {
            type: 'cpf',
            value: '',
          },
        },
        insurance: {
          id: '',
          name: '',
          type: '',
          plan: {
            id: '',
            name: '',
          },
          record: '',
          validity: '',
          imageUrl: '',
        },
        hospitalDischarge: {
          reason: '',
          notes: '',
        },
        items: [],
        reason: '',
        notes: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';

#modal-hospital-discharge {
  .card-cashier {
    background-color: $gray-color-ultra-light;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    padding: $layout-spacing-sm $layout-spacing;
    .card-price {
      color: $warning-color;;
      font-weight: bold;
      font-size: .9rem;
    }
  }
}
</style>
