<template>
  <tr class="table-row">
    <td class="text-center" width="60px">
      <strong>{{ data.startDate | date('HH:mm') }}</strong>
    </td>
    <td class="text-center" width="45px">
      <dx-dropdown :items="statuses" label="name"
                   class="input-group-addon appointment-status"
                   @select="setStatus"
                   :disabled="data.status === 'blocked' || data.status === 'available'"
                   :max-height="350">
        <span class="square c-hand" :title="statusName"
            :style="{backgroundColor: statusColor}"></span>
        <template v-slot:item="{ item }">
          <div class="tile tile-centered c-hand">
            <div class="tile-icon">
              <span class="square" :style="{backgroundColor: item.color}"></span>
            </div>
            <div class="tile-content">{{ item.name }}</div>
          </div>
        </template>
      </dx-dropdown>
      <div v-if="data.status === 'waiting' && data.arrivedAt">
        <small :class="setStatusColor()">
          {{ $timeDiff(data.arrivedAt, now, 'HH:mm') }}
        </small>
      </div>
    </td>
    <td style="width: 30%;" class="text-ellipsis"
        @click="openMedicalRecord(data.patient.id)"
        :class="data.status !== 'blocked' ? 'c-hand' : ''">
      <span>{{ data.patient.name }}</span>
      <span v-if="!data.patient.id" class="text-warning">
        (não cadastrado)
      </span><br>
      <small>{{ data.patient.birthDate | dateOld(true) }}</small><br>
      <div class="text-warning"><small>{{ data.notes }}</small></div>
    </td>
    <td class="text-ellipsis hide-sm"
        :class="data.status !== 'blocked' ? 'c-hand' : ''"
        @click="openMedicalRecord(data.patient.id)">
      <div v-if="data.insurance">
        {{ customName(data.insurance) }}
      </div>
      <small v-if="expenseNames.length > 0">
        <span class="text-info">{{ expenseNames[0] }}</span>
        <span
          v-if="expenseNames.length > 1"
          class="label text-primary tooltip hover-link ml-1 "
          :data-tooltip="expenseNames.slice(1).join('\n')">
          +{{ expenseNames.length -1 }}
        </span>
      </small>
      <small v-else-if="data.type">{{ types.getName(data.type) }}</small>
    </td>
    <td
      class="text-center hide-sm hide-md"
      :style="{ width: hasPCSModule ? '150px' : '130px' }"
    >
      <button class="btn btn-action btn-icon btn-gray btn-sm mr-1"
              :disabled="!data.patient.id || loading"
              :class="{loading, tooltip: !loading}"
              data-tooltip="Laudo"
              @click="openMedicalReport(data.id)">
        <fa-icon :icon="['fal', 'file-medical-alt']"></fa-icon>
      </button>
      <button
        v-if="hasPCSModule"
        class="btn btn-action btn-icon btn-gray btn-sm mr-1"
        :class="{ loading: pcsCalling, tooltip: !pcsCalling }"
        :disabled="!data.patient.id"
        data-tooltip="Chamar paciente"
        @click="callPatient"
      ><fa-icon :icon="['fal', 'bullhorn']"></fa-icon></button>
      <button class="btn btn-action btn-icon btn-gray btn-sm tooltip mr-1"
              :disabled="!data.patient.id"
              data-tooltip="Anexos"
              @click="attachment">
        <fa-icon :icon="['fal', 'paperclip']"></fa-icon>
      </button>
      <router-link
        :disabled="!data.patient.id"
        :to="`/patients/${data.patient.id}/edit`"
        class="btn btn-action btn-icon btn-gray btn-sm tooltip tooltip-left mr-1"
        data-tooltip="Cadastro do paciente">
        <fa-icon :icon="['fal', 'user']"></fa-icon>
      </router-link>
      <button class="btn btn-action btn-icon btn-gray btn-sm tooltip tooltip-left"
              :disabled="!data.patient.id"
              data-tooltip="Histórico de laudos"
              @click="medicalReportHistory">
        <fa-icon :icon="['fal', 'history']"></fa-icon>
      </button>
    </td>
  </tr>
</template>

<script>
import moment from 'moment';
import {
  mapActions,
  mapState,
  mapGetters,
} from 'vuex';
import appointmentStatuses,
{
  getColor,
  getName,
} from 'src/data/appointment-statuses';
import * as types from 'src/data/appointment-types';
import { customName } from 'src/helpers/insurance';
import { pcsMainUrl } from '@/config';

export default {
  props: {
    data: {},
  },
  data() {
    return {
      statuses: appointmentStatuses,
      types,
      customName,
      loading: false,
      pcsCalling: false,
    };
  },
  computed: {
    ...mapState({
      now: ({ now }) => now,
      user: ({ auth }) => auth.user,
    }),
    ...mapGetters({
      hasModule: 'hasModule',
    }),
    expenseNames() {
      return this.data.expenses
        .map(expense => `${expense.return ? '[Retorno]' : ''} ${expense.name} (${expense.code})`)
        .sort();
    },
    statusColor() {
      return getColor(this.data.status);
    },
    statusName() {
      return getName(this.data.status);
    },
    hasPCSModule() {
      return this.hasModule('pcs');
    },
  },
  methods: {
    ...mapActions({
      loadCalendar: 'loadCalendar',
      loadAttendanceConfig: 'loadAttendanceConfig',
    }),
    attachment() {
      this.$emit('attachment', {
        appointmentId: this.data.id,
        patientId: this.data.patient.id,
      });
    },
    medicalReportHistory() {
      this.$emit('medicalReportHistory', {
        appointmentId: this.data.id,
        patientId: this.data.patient.id,
      });
    },
    async callPatient() {
      this.pcsCalling = true;
      try {
        const config = await this.loadAttendanceConfig();
        if (config) {
          const data = {
            place: config.placeId,
            patient: {
              name: this.data.patient.name,
            },
            room: {
              name: config.roomName,
            },
          };
          await this.$http.post(`${pcsMainUrl}/ticket/call`, data);
        }
      } catch (e) {
        this.$toast.error(e);
      }
      this.pcsCalling = false;
    },
    setStatus({ key }) {
      if (!this.allowServiceWithoutAccount()) {
        this.$toast.error(`Não será possível alterar o status.
           Ação bloqueada pela administração.`, { timeout: 8000 });
        return;
      }

      const params = {
        status: key,
      };
      if (key === 'waiting') {
        params.arrivedAt = moment().format('YYYY-MM-DDTHH:mm');
        this.data.arrivedAt = params.arrivedAt;
      } else if (!['in_attendance', 'finished', 'report', 'payment', 'screening'].includes(key)) {
        params.arrivedAt = null;
        this.data.arrivedAt = null;
      }
      this.updateAppointment(this.data.id, params)
        .catch(() => {});
      this.data.status = key;
    },
    setStatusColor() {
      const timeDiff = this.$timeDiff(this.data.arrivedAt, this.now, 'HH:mm');
      if (timeDiff > '00:09' && timeDiff < '00:20') {
        return 'text-error';
      }
      if (timeDiff > '00:20') {
        return 'text-error text-bold';
      }
      return 'text-dark';
    },
    updateAppointment(id, data) {
      return this.$httpX.put(`/appointments/${id}`, data);
    },
    openMedicalRecord(id) {
      if (!id) {
        this.$toast.show('Paciente não cadastrado na recepção!', {
          type: 'error',
          timeout: 5000,
        });
        return;
      }

      this.$router.push(`/patients/${id}/medical-records?appointmentId=${this.data.id}`);
    },
    openMedicalReport(appointmentId) {
      this.loading = true;

      return this.$httpX
        .get(`/medical-reports/appointment/${appointmentId}`)
        .then(({ data }) => {
          if (data.id) {
            this.$router
              .push(`/medical-reports/${data.id}`);
          }
        })
        .catch((e) => {
          if (e.response
            && e.response.data
            && e.response.data.message) {
            this.$toast.show(e.response.data.message, { type: 'error' });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    allowServiceWithoutAccount() {
      if (this.user.branch.settings
        && this.user.branch.settings['clinical.blockServiceWithoutAccount']) {
        return !this.user.branch.settings['clinical.blockServiceWithoutAccount'];
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/scss/variables';
  .table-row {
    .appointment-status {
      &.dropdown > a {
        display: block;
        margin-top: .05rem;
        &:focus {
          box-shadow: none;
        }
      }
      .tile-content {
        line-height: $line-height;
        text-align: left;
        white-space: nowrap;
      }
    }
  }
</style>
