<template>
  <st-side-page class="hospitalization-patient">
    <template slot="side">
      <div class="patient-image" :style="bgImage"
           :class="{'show-options': showImageOptions}">
        <div class="patient-image-options">
          <template v-if="bgImage">
            <button class="btn btn-icon btn-gray tooltip" data-tooltip="Remover foto"
                    @click="removeImage()">
              <fa-icon :icon="['fal', 'times']"/>
            </button>
          </template>
          <template v-else>
            <button class="btn btn-icon btn-gray tooltip" data-tooltip="Enviar foto"
                    @click="image.showOptions = true">
              <fa-icon :icon="['fal', 'upload']"/>
              <input type="file" name="image" accept="image/*"
                     @change="loadImage">
            </button>
            <button class="btn btn-icon btn-gray tooltip" data-tooltip="Tirar foto"
                    @click="openWebCam" :disabled="!hasWebcam">
              <fa-icon :icon="['fal', 'camera']"/>
            </button>
          </template>
        </div>
      </div>
    </template>
    <div class="container">
      <div class="columns">
        <div class="column col-3 col-md-4 col-sm-12 form-group"
             :class="{'has-error': $v.form.identity.value.$error}">
          <label for="identity" class="form-label">CPF</label>
          <div class="input-group">
            <input type="text" id="identity"
                   class="form-input"
                   v-model="form.identity.value"
                   @blur="$v.form.identity.value.$touch()"
                   @change="loadEntityPatient"
                   :disabled="!canEditIdentity"
                   placeholder="000.000.000-00" v-mask-cpf>
            <span class="input-group-addon loading"
                  style="width: 30px"
                  v-if="entityLoading"
            />
          </div>
          <template v-if="$v.form.identity.value.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.identity.value.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.identity.value.cpf">CPF inválido</div>
          </template>
        </div>
        <div class="column col-4 col-md-4 col-sm-12 form-group"
             :class="{'has-error': $v.form.name.$error}">
          <label for="name" class="form-label">Nome completo</label>
          <input type="text" id="name" class="form-input"
                 v-model="form.name"
                 @blur="$v.form.name.$touch()" @change="loadEntityByNameAndDob"
                 placeholder="Nome" autocomplete="nope">
          <template v-if="$v.form.name.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.name.required">Campo obrigatório</div>
          </template>
        </div>
        <div class="column col-3 col-sm-12 form-group"
             :class="{'has-error': $v.form.birthDate.$error}">
          <label class="form-label">Nascimento</label>
          <dx-input-date
            id="birth-date" class="form-input" v-model="form.birthDate"
            @blur="$v.form.birthDate.$touch()" @change="loadEntityByNameAndDob"
          />
          <template v-if="$v.form.birthDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.birthDate.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.birthDate.date">Data inválida</div>
          </template>
        </div>
        <div class="column col-2 col-sm-12 form-group"
             :class="{'has-error': $v.form.gender.$error}">
          <label class="form-label">Sexo</label>
          <select id="gender" class="form-select"
                  v-model="form.gender" @change="$v.form.gender.$touch()">
            <option value="">[Selecione]</option>
            <option value="female">Feminino</option>
            <option value="male">Masculino</option>
          </select>
          <template v-if="$v.form.gender.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.gender.required">Campo obrigatório</div>
          </template>
        </div>
      </div>
      <div class="columns">
        <div class="column col-4 col-sm-12 form-group"
             :class="{'has-error': $v.form.phone.$error}">
          <label for="phone" class="form-label">Telefone</label>
          <input type="text" id="phone" class="form-input"
                 v-model="form.phone" @blur="$v.form.phone.$touch()"
                 maxlength="14" placeholder="(00) 0000-0000"
                 autocomplete="nope" v-mask-phone.br>
          <template v-if="$v.form.phone.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.phone.phone">Telefone inválido</div>
          </template>
        </div>
        <div class="column col-4 col-sm-12 form-group"
             :class="{'has-error': $v.form.cellphone.$error}">
          <label for="cellphone" class="form-label">Celular</label>
          <input type="text" id="cellphone" class="form-input"
                 v-model="form.cellphone" autocomplete="nope"
                 @blur="$v.form.cellphone.$touch()"
                 placeholder="(00) 0 0000-0000" v-mask-phone.br>
          <template v-if="$v.form.cellphone.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.cellphone.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.cellphone.phone">Telefone inválido</div>
          </template>
        </div>
        <div class="column col-4 col-sm-12 form-group"
             :class="{'has-error': $v.form.email.$error}">
          <label for="email" class="form-label">Email</label>
          <input type="text" id="email" class="form-input"
                 v-model="form.email" @blur="$v.form.email.$touch()"
                 placeholder="nome@email.com" autocomplete="nope">
          <template v-if="$v.form.email.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.email.email">Email inválido</div>
          </template>
        </div>
      </div>
      <div class="divider"></div>
      <div class="columns">
        <div class="column col-3 col-md-6 col-sm-12 form-group"
             :class="{'has-error': $v.form.address.postalCode.$error}">
          <label for="address-postal" class="form-label">CEP</label>
          <div class="input-group">
            <input type="text" id="address-postal" class="form-input"
                   v-model="form.address.postalCode"
                   @blur="$v.form.address.postalCode.$touch()"
                   placeholder="00.000-000"
                   @change="loadAddress" v-mask-cep autocomplete="nope">
            <button class="btn btn-neutral btn-icon btn-action input-group-btn"
                    :disabled="addressLoading" :class="{loading: addressLoading}">
              <fa-icon :icon="['fal', 'search']"></fa-icon>
            </button>
          </div>
          <template v-if="$v.form.address.postalCode.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.address.postalCode.cep">CEP inválido</div>
          </template>
        </div>
        <div class="column col-9 col-md-12 col-sm-12 form-group">
          <label for="address-addressLine1" class="form-label">Logradouro</label>
          <input type="text" id="address-addressLine1" name="address-addressLine1"
                 class="form-input" v-model="form.address.addressLine1"
                 placeholder="Avenida Brasil, 1000" autocomplete="nope">
        </div>
        <div class="column col-3 col-md-6 col-sm-12 form-group">
          <label for="address-addressLine2" class="form-label">Complemento</label>
          <input type="text" id="address-addressLine2" name="address-addressLine2"
                 class="form-input" v-model="form.address.addressLine2"
                 placeholder="casa, sobrado, loja..." autocomplete="nope">
        </div>
        <div class="column col-4 col-md-6 col-sm-12 form-group">
          <label for="address-neighborhood" class="form-label">Bairro</label>
          <input type="text" id="address-neighborhood" name="address-neighborhood"
                 placeholder="Bairro" v-model="form.address.neighborhood"
                 class="form-input" autocomplete="nope">
        </div>
        <div class="column col-3 col-md-6 col-sm-12 form-group">
          <label for="address-city" class="form-label">Cidade</label>
          <input type="text" id="address-city" name="address-city"
                 class="form-input" v-model="form.address.city"
                 placeholder="Cidade" autocomplete="nope">
        </div>
        <div class="column col-2 col-md-6 col-sm-12 form-group">
          <label class="form-label">Estado</label>
          <select id="address-state" name="address-state"
                  class="form-select" v-model="form.address.state">
            <option value="">[Selecione]</option>
            <option v-for="(text, value) in states"
                    :value="value" :key="value">{{ value }}</option>
          </select>
        </div>
      </div>
      <div class="columns">
        <div class="column col-12 form-group">
          <label class="form-label">Observações</label>
          <textarea id="notes" class="form-input" rows="2"
                    v-model="form.notes"/>
        </div>
      </div>
    </div>
  </st-side-page>
</template>

<script>
import states from 'src/data/states';
import { hasWebcam } from 'src/helpers/media';
import { email, required } from 'vuelidate/lib/validators';
import { mergeFrom } from 'src/helpers/object';
import formMixin from 'src/mixins/form';
import {
  cep,
  cpf,
  date,
  maxDate,
  phone,
} from '../../../../data/validators';

export default {
  props: {
    id: {
      type: String,
    },
  },
  mixins: [
    formMixin,
  ],
  data() {
    return {
      hasWebcam: false,
      loading: false,
      entityLoading: false,
      addressLoading: false,
      states,
      image: this.imageBlankForm(),
      form: this.blankForm(),
    };
  },
  computed: {
    bgImage() {
      let backgroundImage = null;

      if (this.image.preview) {
        backgroundImage = `url('${this.image.preview}')`;
      } else if (this.form.imageUrl) {
        backgroundImage = `url('${this.form.imageUrl}?w=200&h=200')`;
      }
      return backgroundImage ? { backgroundImage } : null;
    },
    showImageOptions() {
      if (this.image.showOptions) {
        return true;
      }
      return this.bgImage === null;
    },
    canEditIdentity() {
      return !this.form.id
        || !this.form.identity.value
        || !this.$v.form.identity.value.cpf;
    },
  },
  async mounted() {
    this.hasWebcam = await hasWebcam();
    if (this.id) {
      this.load();
    }
  },
  validations() {
    const rules = {
      form: {
        name: { required },
        birthDate: { date, required, maxDate: maxDate() },
        gender: { required },
        identity: {
          value: { cpf, required },
        },
        phone: { phone },
        cellphone: { phone },
        email: { email },
        address: {
          postalCode: { cep },
        },
      },
    };
    rules.nameAndDob = ['form.name', 'form.birthDate'];

    return rules;
  },
  methods: {
    load() {
      return this.$http
        .get(`/patients/${this.id}`)
        .then(({ data }) => {
          this.form = this.formatEntity(data);
          this.$emit('getInsurance', this.form.patient.insurance);
        })
        .catch(() => {});
    },
    loadImage(e) {
      e.preventDefault();
      if (e.target.files[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.image.file = e.target.files[0];
        const reader = new FileReader();
        // eslint-disable-next-line no-shadow
        reader.onload = (e) => {
          this.image.preview = e.target.result;
          this.image.showOptions = false;
        };
        reader.readAsDataURL(this.image.file);
      } else {
        this.image.file = null;
        this.image.showOptions = false;
      }
    },
    async openWebCam() {
      this.image.showWebCam = true;
      this.image.showOptions = true;
    },
    loadEntityByNameAndDob() {
      if (this.$v.nameAndDob.$invalid) return;
      const params = {
        name: this.form.name,
        birthDate: this.form.birthDate,
        relType: 'patient',
      };
      this.$http
        .get('/entities', { params })
        .then(({ data }) => {
          if (data.items && data.items.length) {
            const entity = data.items[0];
            this.form = this.formatEntity(entity);
          }
        })
        .catch(() => {});
    },
    loadEntityPatient() {
      if (this.form.identity.value.trim() === ''
        || this.form.identity.value.length < 14) {
        return;
      }
      this.entityLoading = true;
      const identity = this.onlyNumbers(this.form.identity.value);
      this.$http
        .get(`/entities/${identity}?relType=patient`)
        .then(({ data }) => {
          this.form = this.formatEntity(data);
          this.$emit('getInsurance', this.form.patient.insurance);
        })
        .catch(() => {})
        .finally(() => {
          this.entityLoading = false;
        });
    },
    loadAddress() {
      const postalCode = this.onlyNumbers(this.form.address.postalCode);
      if (postalCode && postalCode.length === 8) {
        this.addressLoading = true;
        this.$cep.get(postalCode)
          .then((data) => {
            this.form.address.city = data.city;
            this.form.address.state = data.state;
            this.form.address.neighborhood = data.neighborhood;
            this.form.address.addressLine1 = data.street;
          })
          .catch(() => {})
          .then(() => {
            this.addressLoading = false;
          });
      }
    },
    removeImage() {
      this.image = this.imageBlankForm();
      this.form.imageUrl = null;

      if (!this.form.id) {
        return;
      }
      this.$http
        .delete(`/entities/${this.form.id}/images`)
        .catch(() => {});
    },
    imageBlankForm() {
      return {
        file: null,
        preview: null,
        showOptions: false,
        showWebCam: false,
      };
    },
    validate() {
      this.$v.form.$touch();
      return !this.$v.form.$error;
    },
    save() {
      if (!this.validate) {
        return false;
      }

      const data = this.clone(this.form);
      data.addresses = [data.address];

      return this.$http.post('/patients', data)
        .then(({ data: patientData }) => patientData)
        .catch(() => {});
    },
    formatEntity(data) {
      const entity = mergeFrom(this.blankForm(), data);
      if (data.addresses && data.addresses.length > 0) {
        [entity.address] = data.addresses;
      }
      return entity;
    },
    blankForm() {
      return {
        id: null,
        identityId: '',
        type: '',
        name: '',
        birthDate: '',
        gender: '',
        identity: {
          type: '',
          value: '',
        },
        phone: '',
        cellphone: '',
        email: '',
        address: {
          id: '',
          type: '',
          postalCode: '',
          addressLine1: '',
          addressLine2: '',
          neighborhood: '',
          city: '',
          state: '',
        },
        addresses: [],
        patient: {
          active: true,
          allergy: false,
          cns: '',
          fatherName: '',
          motherName: '',
          planId: '',
          insurance: {
            id: '',
            name: '',
            plan: {
              id: '',
              name: '',
            },
            record: '',
            validity: '',
          },
          profiles: [],
        },
        notes: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';

.hospitalization-patient {
  .side-page-side {
    border-right: none;
    max-width: 5.6rem;
    padding: 0;
  }
  .side-page-content {
    background-color: $light-color;
    .container {
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
    }
  }
  .patient-image {
    margin-top: $layout-spacing;
    background: $gray-color-light center no-repeat;
    background-size: cover;
    border-radius: $border-radius;
    height: 5rem;
    width: 5rem;
    &:not(.show-options):hover {
      .patient-image-options {
        opacity: 1;
        background-color: rgba($gray-color-light, .97);
      }
    }
    &.show-options {
      .patient-image-options {
        opacity: 1;
      }
    }
  }
  .patient-image-options {
    align-items: center;
    background-color: $gray-color-light;
    border-radius: $border-radius;
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0;
    transition: all .3s ease;
    .btn {
      margin: calc($layout-spacing / 2);
      width: 25px;
      height: 25px;
      position: relative;
      svg {
        width: .7rem;
      }
      input {
        bottom: 0;
        display: block;
        left: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: $zindex-0;
      }
    }
  }
}
</style>
